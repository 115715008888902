// file   : API.js
// purpose: use axios to handle APIs provided by server

import axios from 'axios'
import { PORT, TOKEN_NOT_VALID } from './config'

// default options for axios instance
const defaultOptions = {
  baseURL: PORT,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json'
  }
}

const axiosInstance = axios.create(defaultOptions)

// attach access token to request before sending request to server
axiosInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('access')
  config.headers.Authorization = 
    accessToken 
    ? `Bearer ${accessToken}`
    : ''
  return config
})

// process response before returning it as result 
// if response is a token error, refresh the tokens and resend the request 
// if failed, redirect to login screen
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;
    if (error.response.data.code !== TOKEN_NOT_VALID) {
      console.log('it is not a token error')
      return Promise.reject(error);
    }

    // if refreshing tokens failed, return as error to prevent infinite loop
    if (originalRequest.url === 'token/refresh/') {
      return Promise.reject(error);
    }

    console.log('it is a token error')
    return API.refreshToken({
      refresh: localStorage.getItem('refresh')
    })
    .then(res => {
      console.log('refresh token success')
      const { access, refresh } = res.data
      window.localStorage.setItem('access', access)
      window.localStorage.setItem('refresh', refresh)
      error.response.config.headers['Authorization'] = 'Bearer ' + access
      return axios(error.response.config);
    })
    .catch(error => {
      console.log('got error when refreshing token, redirect to login page')
      localStorage.clear()
      window.location.replace('/login')
      return Promise.reject(error);
    })
  }
)

// class that constructs axiosInstance and contains all functions to call API
export default class coreAPI {
  constructor() {
    this._apiCore = axiosInstance
  }

  signupUser(userData) {
    return this._apiCore.post(`users/`, userData)
  }

  loginUser(userData) {
    return this._apiCore.post(`token/`, userData)
  }

  getUser(userId) {
    return this._apiCore.get(`users/${userId}/`)
  }

  updateUser(userData, userId) {
    return this._apiCore.put(`users/${userId}/`, userData)
  }

  refreshToken(refreshToken) {
    return this._apiCore.post(`token/refresh/`, refreshToken)
  }

  verifyToken(token) {
    return this._apiCore.post(`token/verify/`, token)
  }

  getCourses() {
    return this._apiCore.get(`courses/`)
  }

  getUserModule(userId) {
    return this._apiCore.get(`userModule/${userId}`)
  }

  submitModule(moduleData) {
    return this._apiCore.post(`record_result/`, moduleData)
  }

  updateModuleResult(moduleData) {
    return this._apiCore.put(`record_result/`, moduleData)
  }

  sendResetPasswordEmail(userData) {
    return this._apiCore.post(`sendEmail`, userData, { withCredentials: true })
  }

  submitForgotPassword(userData) {
    return this._apiCore.post(`forgetPassword`, userData, { withCredentials: true })
  }

  getPresignedUrl(fileData) {
    return this._apiCore.post(`generate_presigned/`, fileData)
  }

  createCourse(courseData) {
    return this._apiCore.post(`course/`, courseData)
  }

  deleteCourse(courseId) {
    return this._apiCore.delete(`course/${courseId}`)
  }

  editCourse(courseId, courseData) {
    return this._apiCore.put(`course/${courseId}/`, courseData)
  }

  createModule(moduleData) {
    return this._apiCore.post(`moduleQuiz/`, moduleData)
  }

  deleteModule(moduleId) {
    return this._apiCore.delete(`module/${moduleId}`)
  }

  editModule(moduleId, moduleData) {
    return this._apiCore.put(`moduleQuiz/${moduleId}/`, moduleData)
  }

  archive_content(deleteMap){
    return this._apiCore.post(`archive_content/`, deleteMap)
  }

  updateModuleStatus(moduleId, moduleStatus) {
    return this._apiCore.post(`moduleStatus/${moduleId}/`, moduleStatus)
  }

  getAllResults() {
    return this._apiCore.get(`getAllResults/`)
  }

  requestApiKey(userId, request) {
    const url = `api_keys/${userId}/`
    if (request === 'get') {
      return this._apiCore.get(url)
    } else if (request === 'post') {
      return this._apiCore.post(url)
    } else if (request === 'put') {
      return this._apiCore.put(url)
    } else if (request === 'delete') {
      return this._apiCore.delete(url)
    }
  }
}

export const API = new coreAPI()
// file   : currentUserSlice.js
// purpose: redux slice managing states of current user

import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  user: null,
  isLoggedIn: false,
  isAdmin: false
}

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.user = action.payload
    },
    authLogin: (state, action) => {
      state.user = action.payload
      state.isLoggedIn = true
    },
    authLogout: (state) => {
      state.user = null
      state.isLoggedIn = false
      state.isAdmin = false
    },
    authAdminLogin: (state, action) => {
      state.user = action.payload
      state.isLoggedIn = true
      state.isAdmin = true
    }
  }
})

export const { 
  setCurrentUser, 
  authLogin,
  authLogout,
  authAdminLogin
} = currentUserSlice.actions

export const selectCurrentUser = state => state.currentUser.user
export const selectCurrentAuth = state => state.currentUser.isLoggedIn
export const selectCurrentisAdmin = state => state.currentUser.isAdmin

export default currentUserSlice.reducer
// file   : Collapsible.js
// purpose: each collapsible corresponsed to a course and contains links to other modules
//          it is a child component in SideMenu.js

import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../redux/currentUserSlice'
import './stylesheets/Collapsible.css'

const Collapsible = (props) => {
  const { id, course_name, modules } = props.course
  const [isActive, setIsActive] = useState(false)
  const [maxHeight, setMaxHeight] = useState('')

  const user = useSelector(selectCurrentUser)
  const isAdmin = user && (user.privilege === 'admin')

  // if its admin, redirect to AdminEditModulePage else redirect to ModulePage
  const destination = (moduleid) => isAdmin ? `/admin/editmodule/${id}/${moduleid}` : `/course/${id}/module/${moduleid}`

  // when expand, expand to the appropriate height 
  useEffect(() => {
    if (document.getElementsByClassName('collapsible-content-list')[props.collapsibleNo-1]) {
      setMaxHeight(isActive ? '0px' : document.getElementsByClassName('collapsible-content-list')[props.collapsibleNo-1].scrollHeight  + 'px')
    }
  }, [isActive, props.collapsibleNo])

  return (
    <div className='collapsible-container'>
      <li className='collapsible-item'>
        <p className='collapsible-title' onClick={() => setIsActive(!isActive)}>
          {course_name}
        </p>
        <ol className='collapsible-content-list' style={{maxHeight: maxHeight}}>
          {modules.map((m) => {
            return (
              <li key={m.id} className='collapsible-content-item'>
                <NavLink to={destination(m.id)} className='collapsible-content-item-link' activeClassName='active-link'>
                  {m.title}
                </NavLink>
              </li>
            )    
          })}
        </ol>
      </li>
    </div>
  )
}

export default Collapsible
// file   : AdminRoute.js
// purpose: for routes that can only be accessed by the admin

import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCurrentAuth, selectCurrentisAdmin } from '../../redux/currentUserSlice'

const AdminRoute = ({children, ...rest}) => {
  let isLoggedIn = useSelector(selectCurrentAuth)
  let isAdmin = useSelector(selectCurrentisAdmin)

  return (
    <Route {...rest} render={() => {
      return isLoggedIn === true 
        ? (isAdmin ? children : <Redirect to='/forbidden' />)
        : <Redirect to='/login' />
    }} />
  )
}

export default AdminRoute
// file   : ModuleContent.js
// purpose: shows the module content for a module page, with 
//          combinations of videos, images, quizzes, and articles

import React, { useEffect, useState } from 'react'
import { withRouter, Link } from 'react-router-dom'
import './stylesheets/ModuleContent.css'
import ModuleVideo from './ModuleVideo'
import ModuleImage from './ModuleImage'
import ModuleQuiz from './ModuleQuiz'
import ModuleArticle from './ModuleArticle'
import ModuleModal from './ModuleModal'
import { useSelector, useDispatch } from 'react-redux'
import { selectIsActiveSideMenu } from '../redux/sideMenuSlice'
import { selectCourse } from '../redux/coursesSlice'
import { selectCurrentAuth, selectCurrentUser } from '../redux/currentUserSlice'
import { selectModuleResult, updateModuleResult, addModuleResult } from '../redux/moduleResultsSlice'
import { useParams } from 'react-router-dom'
import { API } from '../API'
import { DefaultLoader } from './Loaders'

const ModuleContent = ({ history }) => {
  const { courseid, moduleid } = useParams()
  const course = useSelector(state => selectCourse(state, courseid))
  const module = (course?.modules || []).find(m => m.id === parseInt(moduleid))
  const isActiveSideMenu = useSelector(selectIsActiveSideMenu)
  const user = useSelector(selectCurrentUser)
  const isLoggedIn = useSelector(selectCurrentAuth);
  const moduleResult = useSelector(state => selectModuleResult(state, module?.id))
  const dispatch = useDispatch()

  // store selected answers to questions in the module as array
  const [answers, setAnswers] = useState([])
  const [isSubmit, setIsSubmit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isFailedResponse, setIsFailedResponse] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState(null)
  let isPassed = false
  let isActive = isActiveSideMenu ? 'sidemenu-active' : null
  let questionNo = 0
  let numQuestions = 0

  useEffect(() => {
    if (!course || !module) {
      history.replace('/404')
    }
  }, [course, module, history]);
  
  useEffect(() => {
    if (!module?.content) {
      return;
    }

    setAnswers([])
    setIsFailedResponse(false)
    setIsSubmit(false)
    
    // count the number of question in the module content,
    // if it's more than 0, initialise the array with zeroes
    for (let i = 0; i < module.content.length; i++) {
      if (module.content[i].type === 'question') {
        numQuestions++
      }
    } 
    if (numQuestions > 0) {
      setAnswers(new Array(numQuestions).fill(0));
    }
  }, [numQuestions, module?.content])

  // render different module component (video, article, quiz, image) based on content type
  const moduleComponent = (content) => {
    const key = content.id + '-' + content.type
    switch(content.type) {
      case 'video':
        return <ModuleVideo key={key} videoUrl={content.url} />
      case 'article':
        return <ModuleArticle key={key} articleUrl={content.url} />
      case 'question':
        questionNo++
        return <ModuleQuiz key={key} isSubmit={isSubmit} updateAnswers={updateAnswers} questionNo={questionNo} quizContent={content} />
      case 'image':
        return <ModuleImage key={key} imageUrl={content.url} />
      default:
        return null
    }
  }

  // used as function in child ModuleQuiz to update selected answers in local ModuleContent
  const updateAnswers = (idx, score) => {
    answers[idx] = score 
    setAnswers(answers)
  }

  // return false if a question is not answered
  // if question is answered wrongly, set it to 0 for easy calculation of score
  const processAnswers = () => {
    for (let i = 0; i < answers.length; i++) {
      if (answers[i] === 0) return false
      else if (answers[i] === -1) answers[i] = 0
    }
    setAnswers(answers)
    return true
  }

  const handleSubmit = () => {
    let score, numCorrect
    let canSubmit = false
    setIsLoading(true)
    setIsFailedResponse(false)

    // if answers array is 0, it means that there are no questions in module
    // so it's okay to submit, else check if a question is unanswered 
    // and count the score for the module
    if (answers.length  === 0) {
      score = -1
      canSubmit = true
      setIsSubmit(true)
    } else {
      if (processAnswers()) {
        setIsSubmit(true)
        canSubmit = true
        numCorrect = answers.reduce((a, b) => a + b, 0)
        score = Math.round(numCorrect / questionNo * 100)
      } else {
        setIsFailedResponse(true)
        setIsLoading(false)
      }
    }

    if (canSubmit) {
      // if user has done this module before, use PUT request and only choose the best score to update
      // else POST the module result to the database
      if (moduleResult) {
        API.updateModuleResult({
          best_score: score <= moduleResult.best_score ? moduleResult.best_score : score,
          user_id: user.id,
          module_id: module.id,
          current_score: score
        })
        .then(res => {
          dispatch(updateModuleResult(res.data))
          isPassed = score >= module.threshold
          setIsLoading(false)
          setModalData({
            score: score,
            numCorrect: numCorrect,
            moduleTitle: module.title,
            numQuestions: questionNo
          })
          setShowModal(true)
        })
        .catch(_ => {
          setIsLoading(false)
        })
      } else {
        API.submitModule({
          best_score: score,
          user_id: user.id,
          module_id: module.id
        })
        .then(res => {
          dispatch(addModuleResult(res.data))
          isPassed = score >= module.threshold
          setIsLoading(false)
          setModalData({
            score: score,
            numCorrect: numCorrect,
            moduleTitle: module.title,
            numQuestions: questionNo
          })
          setShowModal(true)
        })
        .catch(_ => {
          setIsLoading(false)
        })
      }
    }
  }

  if (!course || !module) {
    return (<></>)
  }

  return (
    <div className={'modulecontent-container ' + (isActive)}>
      <div className='modulecontent-main'>
        {showModal && <ModuleModal result={modalData} setShowModal={(bool) => setShowModal(bool)} threshold={module.threshold}/>}
        <div className='modulecontent-header'>
          <p className='modulecontent-course-title'>
            Course: {course.course_name}
          </p>
          <p className='modulecontent-module-title'>
            {module.title}
          </p>
        </div>
        {module.content.map((c) => {
          return moduleComponent(c)
        })}
        <div className='modulecontent-button-container'>
          {isFailedResponse && 
            <p className='moduleform-failed-message'>
              Please answer all questions before submitting.
            </p>
          }
          {isLoggedIn && !isLoading && !isSubmit && <button disabled={isSubmit} className='modulecontent-button' onClick={handleSubmit}>{'Submit'}</button>}
          {!isLoading && isSubmit && (isPassed ? <button className='modulecontent-button' onClick={() => setShowModal(true)}>Show results</button> : <Link to={'/'} className="modulecontent-button modulehome-button"><span>Learner Home</span></Link>) }
          <DefaultLoader isLoading={isLoading} />
        </div>
      </div>
    </div>
  )
}

export default withRouter(ModuleContent)
// file   : AdminHomeContainer.js
// purpose: parent component for AdminHomeContentCourse and renders all the courses
//          allows admin to click create course button to open modal

import React, { useEffect, useState } from 'react'
import './stylesheets/AdminHomeContent.css'
import AdminHomeContentCourse from './AdminHomeContentCourse'
import { useSelector, useDispatch } from 'react-redux'
import { setAllCourses, selectAllCourses, selectIsRefreshed, setIsRefreshed } from '../../redux/coursesSlice'
import { API } from '../../API'
import AdminCreateCourseModal from './AdminCreateCourseModal'

const AdminHomeContent = () => {
  const dispatch = useDispatch()
  const allCourses = useSelector(selectAllCourses)
  const isRefreshed = useSelector(selectIsRefreshed)
  const [showModal, setShowModal] = useState(false)

  // refresh the content if prompt
  useEffect(() => {
    if (!isRefreshed) {
      API.getCourses()
      .then(res => {
        dispatch(setAllCourses(res.data))
        dispatch(setIsRefreshed(true))
      })
      .catch(err => {
        console.log(err.response)
      })
    }
  })

  return (
    <div className='admin-home-container'>
      <AdminCreateCourseModal setShowModal={(bool) => setShowModal(bool)} showModal={showModal} />
      <button className='admin-home-container-button' onClick={() => setShowModal(true)} type="button">Create Course</button> 
      <div className='admin-home-container-courses'>
        {allCourses && allCourses.map((c) => {
          return <AdminHomeContentCourse key={c.id} course={c} />
        })}
      </div>
    </div>
  )
}

export default AdminHomeContent
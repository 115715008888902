// file   : config.js
// purpose: contain constants that were used in other files

export const PORT = 'https://academy.capitalguardians.com:8443/'
export const LOGIN_NO_USER_FOUND = 'No active account found with the given credentials'
export const TOKEN_NOT_VALID = 'token_not_valid'
export const AWS_BUCKET_NAME = 'cg-academy'
export const IMAGE_FOLDER = 'images'
export const MODULE_IMAGE_FILENAME = 'module-image'
export const COURSE_IMAGE_FILENAME = 'course-image'
export const VIDEO_FOLDER = 'videos'
export const VIDEO_FILENAME = 'module-video'
export const ARTICLE_FOLDER = 'articles'
export const ARTICLE_FILENAME = 'module-article'

// file   : sideMenuSlice.js
// purpose: redux slice managing states of side menu 

import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  isActiveSideMenu: false
}

export const sideMenuSlice = createSlice({
  name: 'sideMenu',
  initialState,
  reducers: {
    activeSideMenu: (state) => {
      state.isActiveSideMenu = true
    },
    notActiveSideMenu: (state) => {
      state.isActiveSideMenu = false
    }
  }
})

export const {
  activeSideMenu,
  notActiveSideMenu
} = sideMenuSlice.actions

export const selectIsActiveSideMenu = state => state.sideMenu.isActiveSideMenu

export default sideMenuSlice.reducer
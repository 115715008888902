// file   : CustomHooks.js
// purpose: contains custom hooks used in other components

import { useLayoutEffect, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { authLogout } from '../redux/currentUserSlice'
import { areValidTokens, getTokens } from '../Auth'
import { useLocation } from 'react-router-dom'

// returns the size of the browser window
const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

// check the validity of the token and react accordingly 
const useCheckToken = () => {
  const dispatch = useDispatch()
  const currentPath = useLocation().pathname
  useLayoutEffect(() => {
    if (areValidTokens(getTokens().accessToken, getTokens().refreshToken)) {
      console.log('tokens are checked')
    } else {
      console.log('session is expired')
      window.localStorage.clear()
      dispatch(authLogout())
    }
  }, [dispatch, currentPath])
}

// check if user clicks outside a component
const useClickOutside = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export {
  useWindowSize,
  useClickOutside,
  useCheckToken
}
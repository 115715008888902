// file   : HomePage.js
// purpose: home page for user containing all courses and modules to be accessed

import React from 'react'
import Header from '../../components/Header'
import HomeProfile from '../../components/HomeProfile'
import Footer from '../../components/Footer'

const HomePage = () => {

  return (
    <div>
      <Header />
      <HomeProfile />
      <span style={{marginBottom: '5rem', display: 'block'}}></span>
      <Footer />
    </div>
  ) 
}

export default HomePage
// file   : ModulePage.js
// purpose: page that displays a single training module where 
//          user can read/watch the content and take quiz

import React from 'react'
import Header from '../../components/Header'
import ModuleContent from '../../components/ModuleContent'

const ModulePage = () => {

  return (
    <div>
      <Header />
      <ModuleContent />
      <span style={{marginBottom: '5rem', display: 'block'}}></span>
      {/* <Footer /> */}
    </div>
  )
}

export default ModulePage
// file   : VerifyLinkForm.js
// purpose: a form for new user coming from generated link to fill in personal details

import React, { useState } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import './stylesheets/Form.css'
import { useHistory } from 'react-router-dom'
import { errorMessage, commonLabel } from './CommonComponents'
import { API } from '../API'
import { useDispatch } from 'react-redux'
import { authLogin } from '../redux/currentUserSlice'
import { DefaultLoader } from './Loaders'

const VerifyLinkForm = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [isFailedResponse, setIsFailedResponse] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = (values) => {
    setIsLoading(true)
    setIsSubmitting(true)
    setIsFailedResponse(false)
    const userData = {
      first_name: values.firstName,
      last_name: values.lastName,
      password: values.password,
    }

    // submit filled-in response to server and redirect to home page if response is success
    API.updateUser(userData, props.payload.user_id)
    .then(res => {
      console.log('updated-data::', res.data) 
      dispatch(authLogin(res.data))
      history.push('/')
    })
    .catch(err => {
      console.log(err.response)
      setIsLoading(false)
      setIsSubmitting(false)
      setIsFailedResponse(true)
    })
  }

  // using formik to create form and validations for form
  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: ''
      }}
      validationSchema={Yup.object({
        firstName: Yup.string()
          .required('First name is required')
          .max(35, 'Must be 35 characters or less')
          .matches(/^[a-zA-Z0-9]*$/, 'Must only contain alphanumeric characters'),
        lastName: Yup.string()
          .required('Last name is required')
          .max(35, 'Must be 35 characters or less')
          .matches(/^[a-zA-Z0-9]*$/, 'Must only contain alphanumeric characters'),
        password: Yup.string()
          .required('Password is required')
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/,
            '8 - 16 characters, at least one letter and one number'
          ),
        confirmPassword: Yup.string()
          .required('Confirming password is required')
          .oneOf([Yup.ref('password'), null], 'Passwords much match')
      })}
      onSubmit={(values, actions) => {
        handleSubmit(values, actions)
      }}
    >
      <div className='form-container'>
        <p className='form-container-title'>Create new profile</p>
        <Form>
          {commonLabel('email', 'Email')}
          <input 
            type="text" 
            name="email" 
            value={props.payload.email} 
            disabled={true} 
          />
          {commonLabel('firstName', 'First name')}
          <Field 
            name='firstName'
            type='text'
            disabled={isSubmitting}
            placeholder='What is your first name?'
          />
          {errorMessage('firstName')}
          {commonLabel('lastName', 'Last name')}         
          <Field 
            name='lastName'
            type='text'
            disabled={isSubmitting}
            placeholder='What is your last name?'
          />
          {errorMessage('lastName')}

          {commonLabel('password', 'New Password')}
          <Field 
            name='password'
            type='password'
            disabled={isSubmitting}
            placeholder='Enter your new password'
          /> 
          {errorMessage('password')}

          {commonLabel('confirmPassword', 'Confirm password')}
          <Field 
            name='confirmPassword'
            type='password'
            disabled={isSubmitting}
            placeholder='Confirm your new password'
          /> 
          {errorMessage('confirmPassword')}

          {isFailedResponse && 
            <p className='form-failed-message'>
              Error occured when updating profile
            </p>
          }
          {!isLoading && <input type="submit" value="Create" />}
          <DefaultLoader isLoading={isLoading} />
        </Form>
      </div>
    </Formik>
  )
}

export default VerifyLinkForm
// file   : UserDetailModal.js
// purpose: modal that shows list of user training records as table

import React, { useState, useEffect, useMemo } from 'react'
import { DefaultModal } from '../Modals'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { useTable, useSortBy } from 'react-table'
import { getBadge } from './AdminHelper'

const useStyles = makeStyles({
  root: {
    margin: '1rem auto',
    width: '90%',
  },
  container: {
    maxHeight: 600
  },
})

// use components from material-ui and hooks from react-table to build this table
const UserDetailModal = (props) => {
  const {showModal, setShowModal, allData, email} = props
  const [data, setData] = useState([])
  const classes = useStyles()

  useEffect(() => {
    if (email) {
      let res = allData.find(e => {
        return e.email === email
      })
      setData(res.module_results)
    }
  }, [data, allData, email])

  const columns = useMemo(() => [
    {
      Header: 'Module',
      accessor: 'module_title'
    },
    {
      Header: 'Last submitted',
      accessor: row => row.last_modify.split(' ')[0],
      id: 'last_modify'
    },
    {
      Header: 'Best score',
      accessor: 'best_score'
    },
    {
      Header: 'Badge',
      accessor: 'badge_style'
    },
  ], [])

  const sortByOptions = useMemo(() => [
    {
      id: 'last_modify',
      desc: true
    }
  ], [])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    initialState: {
      sortBy: sortByOptions
    }
  }, useSortBy)

  return (
    <DefaultModal isOpen={showModal}>
      <div className='modal-clsbutton-container'>
        <button className='modal-clsbutton' onClick={() => setShowModal(false)}>X</button>
      </div>
      <p className='admin-create-course-modal-title'>Training Records</p>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label='sticky table' size='small' {...getTableProps()}>
            <TableHead>
              {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell {...column.getHeaderProps(column.getSortByToggleProps())} align='center'>
                    {column.render('Header')}
                    <span>
                      {' '}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <i className="fas fa-sort-down"></i>
                          : <i className="fas fa-sort-up"></i>
                        : ''}
                    </span>
                  </TableCell>
                ))}
              </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.length > 0 ? rows.map((row, i) => {
                prepareRow(row)
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      if (cell.column.id === 'badge_style') {
                        let isPassed = row.original.best_score >= row.original.threshold
                        return (
                          <TableCell {...cell.getCellProps()} align='center' style={{color: 'green'}}>
                            {isPassed ? getBadge(parseInt(cell.value), () => null, () => null) : 'failed'}
                          </TableCell>
                        )
                      } else if (cell.column.id === 'best_score') {
                        let noQuestion = cell.value === -1
                        return (
                          <TableCell {...cell.getCellProps()} align='center'>
                            {noQuestion ? 'completed'  : cell.render('Cell')}
                          </TableCell>
                        )
                      } else {
                        return (
                          <TableCell {...cell.getCellProps()} align='center'>
                            {cell.render('Cell')}
                          </TableCell>
                        )
                      }
                    })}
                  </TableRow>
                )
              }) : 
              <TableRow>
                <TableCell colSpan={4} style={{textAlign: 'center'}}>No training records for this user</TableCell>
              </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer> 
      </Paper>

    </DefaultModal>
  )
}

export default UserDetailModal
// file   : VerifyLinkPage.js
// purpose: shows form for new user coming from generated links to fill in personal details
//          will also automatically redirect old users from generated links to home page

import React, { useState, useEffect } from 'react'
import queryString from 'query-string';
import { useCheckToken } from '../../components/CustomHooks';
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useHistory } from 'react-router'
import { authLogin, selectCurrentAuth } from '../../redux/currentUserSlice'
import { API } from '../../API';
import { decodeJwt } from '../../Auth';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import VerifyLinkForm from '../../components/VerifyLinkForm'

const VerifyLinkPage = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(selectCurrentAuth)

  // use querystring to fetch token from url 
  const { location: { search } } = props
  const values = queryString.parse(search)
  const [isFirstTime, setIsFirstTime] = useState(false)
  const [payload, setPayload] = useState(null)
  
  useCheckToken()

  // if no token in url, redirect to login screen
  // else, decode refresh token to get user_id and email
  // and fetch user data using user_id

  // if user hasn't set his first/lastname, show VerifyLinkForm
  // else, redirect to home page
  useEffect(() => {
    if (!values.token) {
      return <Redirect to='/login' />
    } else {
      localStorage.clear()
      localStorage.setItem('refresh', values.token)
      const { user_id, email } = decodeJwt(values.token)
      setPayload({
        user_id: user_id,
        email: email
      })

      API.getUser(user_id)
      .then(res => {
        if (res.data.first_name === 'dummy' && res.data.last_name === 'dummy') {
          console.log('dummy names, fill info please')
          setIsFirstTime(true)
        } else {
          dispatch(authLogin(res.data))
          history.push('/')
        } 
      })
      .catch(err => {
        console.log(err.response)
      }) 
    }
  }, [values.token, dispatch, history])
  
  if(isLoggedIn) return <Redirect to='/' />

  return (
    <div>
      <Header />
      {isFirstTime ? <VerifyLinkForm payload={payload} /> : 'Verifying Link'}
      <Footer />
    </div>
  )
}

export default VerifyLinkPage
// file   : AdminProfilePage.js
// purpose: showing the personal details of admin, and where apikey is fetch/refresh/delete

import React from 'react'
import Header from '../../components/Header'
import Profile from '../../components/Profile'
import Footer from '../../components/Footer'
import { useCheckToken } from '../../components/CustomHooks'
import ApiKeyComponent from '../../components/admin/ApiKeyComponent'

const AdminProfilePage = () => {
  useCheckToken()

  return (
    <div>
      <Header /> 
      <Profile />
      <ApiKeyComponent />
      <span style={{marginBottom: '5rem', display: 'block'}}></span>
      <Footer />
    </div>
  )
}

export default AdminProfilePage
// file   : coursesSlice.js
// purpose: redux slice managing states of courses and modules

import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  allCourses: null,
  isRefreshed: false
}

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setAllCourses: (state, action) => {
      state.allCourses = action.payload;
    },
    editCourse: (state, action) => {
      const ind = state.allCourses.findIndex(c => {
        return c.id === action.payload.id
      })
      state.allCourses[ind].course_name = action.payload.course_name
      state.allCourses[ind].course_no = action.payload.course_no
      state.allCourses[ind].course_pic = action.payload.course_pic
    },
    addModule: (state, action) => {
      const cind = state.allCourses.findIndex(c => {
        return c.id === action.payload.courseId
      })
      state.allCourses[cind].modules.push(action.payload.newModule)
    },
    removeModule: (state, action) => {
      const cind = state.allCourses.findIndex(c => {
        return c.id === action.payload.courseId
      })
      const mind = state.allCourses[cind].modules.findIndex(m => {
        return m.id === action.payload.moduleId
      })

      state.allCourses[cind].modules.splice(mind, 1)
    },
    updateModule:(state, action)=>{
      console.log(action.payload)
      const cind = state.allCourses.findIndex(c => {
        return c.id === action.payload.courseId
      })
      const mind = state.allCourses[cind].modules.findIndex(m => {
        return m.id === action.payload.moduleId
      })
      state.allCourses[cind].modules[mind][action.payload.fieldName] = action.payload.fieldValue
    },
    setIsRefreshed: (state, action) => {
      state.isRefreshed = action.payload
    }
  }
})

export const {
  setAllCourses,
  editCourse,
  addModule,
  removeModule,
  updateModule,
  setIsRefreshed
} = coursesSlice.actions

export const selectAllCourses = state => state.courses.allCourses
export const selectIsRefreshed = state => state.courses.isRefreshed
export const selectCourse = (state, courseId) => {
  let cid = parseInt(courseId)
  let found = (state.courses.allCourses || []).find(c => {
    return c.id === cid
  })
  return found
}

export default coursesSlice.reducer
// file   : AdminHomePage.js
// purpose: admin home page showing all modules to be edit, and is 
//          where courses are created/updated/deleted and where modules are deleted

import React from 'react'
import Header from '../../components/Header'
import AdminHomeContent from '../../components/admin/AdminHomeContent'
import Footer from '../../components/Footer'
import { useCheckToken } from '../../components/CustomHooks'
import { DefaultToaster } from '../../components/Toasts'

const AdminHomePage = () => {
  useCheckToken()

  return (
    <div>
      <Header />
      <DefaultToaster />
      <AdminHomeContent />
      <span style={{marginBottom: '5rem', display: 'block'}}></span>
      <Footer />
    </div>
  )
}

export default AdminHomePage
// file   : Profile.js
// purpose: shows the profile details of a user

import React, { useState } from 'react'
import './stylesheets/Form.css'
import { Link } from 'react-router-dom'
import { getAvatar } from './Helper'
import './stylesheets/HeaderMenu.css'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../redux/currentUserSlice'
import { Formik } from 'formik'
import AvatarModal from './AvatarModal'
import { DefaultToaster } from './Toasts'
import { getEdit } from './admin/AdminHelper'
const Profile = () => {
  const user = useSelector(selectCurrentUser)
  const isAdmin = user && (user.privilege === 'admin')
  const editProfileLink = isAdmin ? '/admin/editprofile' : 'editprofile'
  const [showModal, setShowModal] = useState(false)
  const avatarNo = user && (user.avatar ? parseInt(user.avatar) : 1)
  const achievementLink = isAdmin ? '/admin/achievement':'achievement'
  // use Formik to create a form structure to display profile details with disabled inputs
  return (
    <Formik>
      <div className='form-container'>
        <DefaultToaster />
        <AvatarModal showModal={showModal} setShowModal={(bool) => setShowModal(bool)} user={user}/>
        <p className='form-container-title'>Profile</p>
        <img src={getAvatar(avatarNo)} alt="avatar" className="profile-img" onClick={() => setShowModal(true)} />
        <h2 className="profile-name">{user.first_name} {user.last_name}</h2>  
        <div className='profile-container'>
          <label>Email:</label>
          <input 
            type="text" 
            name="email" 
            value={user.email} 
            disabled={true} 
            className='profile-input-text'
          />
        </div>
        <div className='profile-container'>
          <label>Business name:</label>
          <input 
            type="text" 
            name="business_name" 
            value={user.business_name ? user.business_name : ''} 
            disabled={true} 
            className='profile-input-text'
          />
        </div>
        <div className='profile-container'>
          <label>Contact:</label>
          <input 
            type="text" 
            name="contact" 
            value={user.contact ? user.contact : ''} 
            disabled={true} 
            className='profile-input-text'
          />
        </div>
        <Link to={editProfileLink} className="profile-edit-icon">
          {getEdit()}
        </Link>
        <div className="profile-button-container">
          <Link to={achievementLink} className="profile-button"><span>Achievements</span></Link>
        </div>
      </div>
    </Formik>
  )
}

export default Profile
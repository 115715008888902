// file   : PrivateRoute.js
// purpose: for routes that can only be accessed by a service provider (normal user)

import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCurrentAuth, selectCurrentisAdmin } from '../redux/currentUserSlice'

const PrivateRoute = ({children, ...rest}) => {
  let isLoggedIn = useSelector(selectCurrentAuth)
  let isAdmin = useSelector(selectCurrentisAdmin)

  // if admin access this route, redirect him to forbidden 403 page
  // else if an unauthenticated person access this route, redirect him to login page
  return (
    <Route {...rest} render={() => {
      return isLoggedIn === true
        ? (isAdmin ? <Redirect to='/forbidden' /> : children)
        : <Redirect to='/login' />
    }} />
  )
}

export default PrivateRoute
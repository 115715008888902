// file   : HomeContent.js
// purpose: main component for home page, renders all the courses in the module

import React, { useEffect } from 'react'
import './stylesheets/HomeContent.css'
import HomeContentCourse from './HomeContentCourse'
import { useSelector, useDispatch } from 'react-redux'
import { setAllCourses, selectAllCourses } from '../redux/coursesSlice'
import {  selectCurrentUser } from '../redux/currentUserSlice'
import { API } from '../API'
import { selectModuleResults, setModuleResults } from '../redux/moduleResultsSlice'

const HomeContent = () => {
  const dispatch = useDispatch()
  const allCourses = useSelector(selectAllCourses)
  const currentUser = useSelector(selectCurrentUser)
  const allModuleResults = useSelector(selectModuleResults)
  
  // fetch all the courses from database
  useEffect(() => {
    API.getCourses()
    .then(res => {
      dispatch(setAllCourses(res.data))
    })
    .catch(err => {
      console.log(err.response)
    }) 
  }, [dispatch])

  // fetch all the user module results from the database
  useEffect(() => {
    if (!currentUser?.id) {
      return dispatch(setModuleResults([]));
    }
    
    API.getUserModule(currentUser.id)
    .then(res => {
      dispatch(setModuleResults(res.data))
    })
    .catch(err => {
      console.log(err.response)
    })
  }, [currentUser?.id, dispatch])

  return (
    <div className='home-container'>
      <p className='home-container-title'>Courses</p>
      <div className='home-container-courses'>
        {allCourses && allModuleResults && allCourses.map((c) => {
          return <HomeContentCourse key={c.id} course={c} moduleResults={allModuleResults} />
        })}
      </div>
    </div>
  )
}

export default HomeContent
// file   : Profile.js
// purpose: shows the profile details of a user

import React, { useState, useEffect } from "react";
import "./stylesheets/HeaderMenu.css";
import "./stylesheets/Achievement.css";
import { useSelector, useDispatch } from "react-redux";
import { setAllCourses, selectAllCourses } from "../redux/coursesSlice";
import { selectCurrentUser } from "../redux/currentUserSlice";
import { API } from "../API";
import {
  selectModuleResults,
  setModuleResults,
} from "../redux/moduleResultsSlice";

function Achievement() {
  const dispatch = useDispatch();
  const isPassed = (moduleResult, threshold) =>
    moduleResult.best_score >= (threshold === null ? 80 : threshold) ||
    moduleResult.best_score === -1
      ? true
      : false;
  const currentUser = useSelector(selectCurrentUser);
  const allCourses = useSelector(selectAllCourses);
  const allModuleResults = useSelector(selectModuleResults)
  const [completedCourse, setCompletedCourse] = useState([]);

  useEffect(() => {
    API.getCourses()
      .then(res => dispatch(setAllCourses(res.data)))
      .catch(err => console.log(err.response));
  }, [dispatch]);

  // fetch all the user module results from the database
  useEffect(() => {
    if (!currentUser?.id) {
      return dispatch(setModuleResults([]));
    }

    API.getUserModule(currentUser.id)
      .then((res) => {
        dispatch(setModuleResults(res.data));
      })
      .catch(err => console.log(err.response));
  }, [currentUser?.id, dispatch]);

  useEffect(()=>{
    allModuleResults.forEach((res) => {
      allCourses.forEach((cs) => {
        let findModule = cs["modules"].find((m) => (m["id"] === res["module_id"]))
        if (findModule && isPassed(res, findModule["threshold"]) ) {              
          let idx = completedCourse.findIndex(e => e["id"] === cs["id"]);
          if (idx !== -1) completedCourse[idx]["modules"].push({...res,'title':findModule.title})
          else  completedCourse.push({
                  "modules":[{...res,'title':findModule.title}],
                  "id":cs["id"],
                  "course_name": cs["course_name"]
                });
          setCompletedCourse(completedCourse)
        }        
      });
    });
  },[])
  return (
    <div className="achievement-container">
      <p className="title">Completed Courses -</p>
      {
        completedCourse.map((cs)=>(
          <div key={cs.id} className="course-container">
            <p className="course-name">{cs.course_name} -</p>
            <ul className="moduleList">
            {
              cs['modules'].map((m)=>
                (
                  <li key={m.module_id} className="module-container">
                    <div className="module-name">- {m.title}</div>
                    <div className="module-date">Completed on {m.best_score_date.split(' ')[0].split('-').reverse().join('/')}</div>
                  </li>
                )
              )              
            }   
            </ul>         
          </div>
        ))
      }      
    </div>
  );
}

export default Achievement;

import React from 'react'
import Header from '../../components/Header'
import HomeContent from '../../components/HomeContent'
import Footer from '../../components/Footer'

const ContentPage = () => {

  return (
    <div>
      <Header />
      <HomeContent />
      <span style={{marginBottom: '5rem', display: 'block'}}></span>
      <Footer />
    </div>
  )
}

export default ContentPage
// file   : AvatarModal.js
// purpose: modals that display all avatar for selection in ProfilePage

import React, { useState } from 'react'
import { DefaultModal } from './Modals'
import { getAvatar } from './Helper'
import { useDispatch } from 'react-redux'
import { setCurrentUser } from '../redux/currentUserSlice'
import './stylesheets/AvatarModal.css'
import { API } from '../API'
import { successToast } from './Toasts'
import { DefaultLoader } from './Loaders'


const AvatarModal = (props) => {
  const { showModal, setShowModal, user } = props
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  
  const handleUpdateAvatar = (num) => {
    setIsLoading(true)
    API.updateUser({ 
      avatar: num
    }, user.id)
    .then(res => {
      dispatch(setCurrentUser(res.data))
      setIsLoading(false)
      setShowModal(false)
      successToast('Avatar is updated')
    })
    .catch(err => {
      setIsLoading(false)
      console.log(err)
    })
  }

  return (
    <DefaultModal isOpen={showModal}>
      <div className='modal-clsbutton-container'>
        <button className='modal-clsbutton' onClick={() => setShowModal(false)}>X</button>
      </div>
      <p className='avatar-modal-title'>Avatars</p>
      {!isLoading && <div className='avatar-tray'>
        {[...Array(8)].map((_, i) => {
          return <img key={i} src={getAvatar(i + 1)} alt="avatar" onClick={() => handleUpdateAvatar(i + 1)}/>
        })}
      </div>}
      <DefaultLoader isLoading={isLoading} />
      <p className='avatar-modal-message'>Choose an avatar from the options to update your profile</p>
      <span style={{marginBottom: '1rem', display: 'block'}}></span>
    </DefaultModal>
  )
}

export default AvatarModal
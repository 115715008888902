// file   : SignupForm.js
// purpose: displays a form for authenticating a user

import React, { useState } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import './stylesheets/Form.css'
import { errorMessage, commonLabel } from './CommonComponents'
import { Link, useHistory } from 'react-router-dom'
import { API } from '../API'
import { decodeJwt } from '../Auth'
import { useDispatch } from 'react-redux'
import { authLogin, authAdminLogin } from '../redux/currentUserSlice'
import { DefaultLoader } from './Loaders'
import { LOGIN_NO_USER_FOUND } from '../config'

const LoginForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [isFailedResponse, setIsFailedResponse] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  // call api and set access/refresh token from result 
  // then dispatch with user data and redirect to home page
  const handleSubmit = (values) => {
    setIsLoading(true)
    setIsSubmitting(true)
    setIsFailedResponse(false)

    API.loginUser({
      email: values.email,
      password: values.password
    })
    .then(res => {
      setIsSubmitting(true)
      window.localStorage.clear()
      window.localStorage.setItem('access', res.data.access)
      window.localStorage.setItem('refresh', res.data.refresh)  
      const { user_id } = decodeJwt(res.data.access)
      API.getUser(user_id)
      .then(res => {
        if (res.data.privilege === 'admin') {
          dispatch(authAdminLogin(res.data))
          history.push('/admin')
        } else {
          dispatch(authLogin(res.data))
          history.push('/')
        }    
      })
      .catch(err => {
        console.log(err.response)
        setIsLoading(false)
        setIsSubmitting(false)
        setIsFailedResponse(true)
      })
    })
    .catch(err => {
      if (err.response.data.detail === LOGIN_NO_USER_FOUND) {
        setIsLoading(false)
        setIsSubmitting(false)
        setIsFailedResponse(true)
      }
    })
  }

  // use third-party library Formik and Yup to create form and form validations
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .required('Email is required')
          .email('Invalid email address'),
        password: Yup.string()
          .required('Password is required')
      })}
      onSubmit={(values, actions) => {
        handleSubmit(values, actions)
      }}
    >
      <div className='form-container'>
        <p className='form-container-title'>Login</p>
        <Form>
          {commonLabel('email', 'Email')}
          <Field
            name='email'
            type='email'
            disabled={isSubmitting}
            placeholder='Enter your email address'
          />
          {errorMessage('email')}
          <span className='login-form-password-label'>
            {commonLabel('password', 'Password')}
            <Link to='/emailresetpassword' className='forgot-password-link'>
              Forgot your password?
            </Link>
          </span>
          <Field 
            name='password'
            type='password'
            disabled={isSubmitting}
            placeholder='Enter your password'
          />
          {errorMessage('password')}
          {isFailedResponse && 
            <p className='form-failed-message'>
              Please enter the correct username and passsword.
            </p>
          }
          {!isLoading && <input type="submit" value="Login" />}
          <DefaultLoader isLoading={isLoading} />
          <Link to='/signup' className='link-text'>
            Don't have an account? Signup here!
          </Link>
        </Form>
      </div>
    </Formik>
  )
}

export default LoginForm
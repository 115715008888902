// file   : AdminEditCourseModal.js
// purpose: modal that allows admin to update course title or course image

import React, { useEffect, useState } from 'react'
import NativeSelect from "@material-ui/core/NativeSelect";
import './stylesheets/AdminCreateCourseModal.css'
import ImageDropzone from '../ImageDropzone'
import { useDispatch } from 'react-redux'
import { editCourse, setIsRefreshed } from '../../redux/coursesSlice'
import { uploadFileToS3 } from '../../S3Helper'
import { API } from '../../API'
import { successToast, errorToast } from '../Toasts'
import { DefaultModal } from '../Modals'
import { DefaultLoader } from '../Loaders'
import { BootstrapInput } from '../Inputs'
import { STATUS_ALL } from '../Helper'

const AdminEditCourseModal = (props) => {
  const dispatch = useDispatch()
  const { id, course_name, course_pic, course_no, status } = props.course

  const [image, setImage] = useState(null)
  const [hasImage, setHasImage] = useState(false)
  const [imageSrc, setImageSrc] = useState(course_pic)
  const [courseTitle, setCourseTitle] = useState(course_name)
  const [courseStatus, setCourseStatus] = useState(status)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // preview image if image is available
  useEffect(() => {
    if (image) {
      setHasImage(true)
      setImageSrc(URL.createObjectURL(image))
    } else {
      setHasImage(false)
    }
  }, [setHasImage, image])

  const handleSubmit = () => {
    setIsLoading(true)
    setIsSubmitting(true)

    // different update process depends on whether image is updated 
    if(!image) {
      API.editCourse(id, {
        course_name: courseTitle,
        course_no: course_no,
        course_pic: course_pic,
        status: courseStatus,
      })
      .then(res => {
        dispatch(editCourse(res.data))
        dispatch(setIsRefreshed(false))
        setIsLoading(false)
        setIsSubmitting(false)
        successToast('Updated a course successfully')
        props.setShowModal(false)
      })
      .catch(err => {
        errorToast('Error occured when updating a course')
        setIsLoading(false)
        setIsSubmitting(false)
        console.log(err)
      })
    } else {
      uploadFileToS3(image, 'course-image', 'images')
      .then(data => {
        API.editCourse(id, {
            course_name: courseTitle,
            course_no: course_no,
            course_pic: data.fileUrl
        })
        .then(res => {
          dispatch(editCourse(res.data))
          setIsLoading(false)
          setIsSubmitting(false)
          successToast('Updated a course successfully')
          props.setShowModal(false)
        })
      })
      .catch(err => {
        errorToast('Error occured when updating a course')
        setIsLoading(false)
        setIsSubmitting(false)
        console.log(err)
      })
    }
  }

  return (
    <DefaultModal isOpen={props.showModal}>
      <div className='modal-clsbutton-container'>
        <button className='modal-clsbutton' onClick={() => props.setShowModal(false)}>X</button>
      </div>
      <p className='admin-create-course-modal-title'>Edit Course</p>
      <div className='admin-create-course-modal-content'>
        <label className='create-course-label'>Course title:</label>
        <input 
            className='course-title-input' 
            type='text' 
            value={courseTitle}
            disabled={isSubmitting}
            onChange={(e) => setCourseTitle(e.target.value)}
        />
        <label className='create-course-label'>Course Image:</label>
        <ImageDropzone setImage={(file) => setImage(file)} isSubmitting={isSubmitting} />
        {hasImage && <img className='preview-image' src={imageSrc} alt='preview' />}
        <label className="create-course-label">Course Status:</label>
        <NativeSelect
          value={courseStatus}
          onChange={e => setCourseStatus(e.target.value)}
          id="course-status-select"
          input={<BootstrapInput />}
        >
          {Object.values(STATUS_ALL).map((v) => (
            <option value={v} key={v}>{v}</option>
          ))}
        </NativeSelect>
        <div className='admin-create-course-modal-submit-container'>
            {!isLoading && 
              <button 
                className='admin-create-course-modal-submit' 
                type='submit'
                onClick={handleSubmit}
              >
                Update
              </button>
            }
            <DefaultLoader isLoading={isLoading} />
          </div>
      </div>
    </DefaultModal>
  )
}

export default AdminEditCourseModal
// file   : S3Helper.js
// purpose: contains functions to handle AWS S3 

import { API } from './API'
import { 
  AWS_BUCKET_NAME,
  IMAGE_FOLDER,
  MODULE_IMAGE_FILENAME,
  ARTICLE_FOLDER,
  ARTICLE_FILENAME,
  VIDEO_FOLDER, 
  VIDEO_FILENAME
} from './config'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';

// upload file to different folder in S3 (images, json, videos) 
export const uploadFileToS3 = async (file, fileName, folder) => {
  let objectName
  let fileToUpload

  // decide the filename and file to upload
  if (folder === ARTICLE_FOLDER) {
    objectName = fileName + '-' + uuidv4() + '.json'
    fileToUpload = JSON.stringify(file, null, 4)
  } else {
    const fileParts = file.name.split('.')
    const fileType = fileParts[1]
    objectName = fileName + '-' + uuidv4() + '.' + fileType
    fileToUpload = file
  }

  // get presigned url and fields from server
  const presignedRes = await API.getPresignedUrl({
    bucket_name: AWS_BUCKET_NAME,
    object_name: `${folder}/${objectName}`
  })

  // create and fill the formdata
  const formData = new FormData()
  Object.keys(presignedRes.data.fields).forEach(key => {
    formData.append(key, presignedRes.data.fields[key])
  })
  formData.append('file', fileToUpload)

  // pass the formdata to the presigned url
  const uploadRes = await axios.post(presignedRes.data.url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  // return the file url and upload result
  const fileUrl = presignedRes.data.url + folder + '/' + objectName
  return { uploadRes,  fileUrl}
}

// transform the content from new module to those that can be 
// stored directly in database
export const processContent = async (allContent) => {
  let allPromises = []

  // aggregate promises of uploading file to S3 into array
  for (let i = 0; i < allContent.length; i++) {
    if (allContent[i].type === 'image' && allContent[i].file) {
      allPromises.push(uploadFileToS3(allContent[i].file, MODULE_IMAGE_FILENAME, IMAGE_FOLDER))
    } else if (allContent[i].type === 'video' && allContent[i].file) {
      allPromises.push(uploadFileToS3(allContent[i].file, VIDEO_FILENAME, VIDEO_FOLDER))
    } else if (allContent[i].type === 'article') {
      if (!allContent[i].url || allContent[i].url === '') {
        allPromises.push(uploadFileToS3(allContent[i].article, ARTICLE_FILENAME, ARTICLE_FOLDER))
      }
    }
  }

  // resolves Promise to an array of results
  let values = await Promise.all(allPromises)

  // j counter to track promise results
  let j = 0;

  // recreate content to be stored in database using results from promises
  // if there is a file or no previous article url, use url from promises, 
  // else, use url from original content
  for (let i = 0; i < allContent.length; i++) {
    if (allContent[i].type === 'image') {
      if (allContent[i].file) {
        allContent[i] = {
          id: allContent[i].id,
          priority: allContent[i].priority,
          type: allContent[i].type,
          url: values[j].fileUrl
        }
        j++;
      } else {
        allContent[i] = {
          id: allContent[i].id,
          priority: allContent[i].priority,
          type: allContent[i].type,
          url: allContent[i].url
        }
      }
      
    } else if (allContent[i].type === 'video') {
      if (allContent[i].file) {
        allContent[i] = {
          id: allContent[i].id,
          priority: allContent[i].priority,
          type: allContent[i].type,
          url: values[j].fileUrl
        } 
        j++;
      } else {
        allContent[i] = {
          id: allContent[i].id,
          priority: allContent[i].priority,
          type: allContent[i].type,
          url: allContent[i].url
        }
      }
    } else if (allContent[i].type === 'question') {
      allContent[i] = {
        id: allContent[i].id,
        priority: allContent[i].priority,
        type: allContent[i].type,
        body: allContent[i].body,
        option_A: allContent[i].option_A,
        option_B: allContent[i].option_B,
        option_C: allContent[i].option_C,
        option_D: allContent[i].option_D,
        correct_ans: allContent[i].correct_ans
      }
    } else if (allContent[i].type === 'article') {
      if (!allContent[i].url || allContent[i].url === '') {
        allContent[i] = {
          id: allContent[i].id,
          priority: allContent[i].priority,
          type: allContent[i].type,
          url: values[j].fileUrl
        }
        j++;
      } else {
        allContent[i] = {
          id: allContent[i].id,
          priority: allContent[i].priority,
          type: allContent[i].type,
          url: allContent[i].url
        }
      }
    }
  }

  return await allContent
}
// file   : ImageDropzone.js
// purpose: a dropzone to drop or attach any image file

import React, { useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { 
  divStyle,
  baseStyle,
  activeStyle,
  acceptStyle,
  rejectStyle,
  pStyle 
} from './DropzoneStyle'

const ImageDropzone = (props) => {
  // set up the dropzone to accept only one, less than 5mb image file, at a time 
  const { 
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    maxSize: 5242880,
    multiple: false,
    disabled: props.isSubmitting,
    onDropAccepted: (files) => { props.setImage(files[0]) }
  })

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [isDragAccept, isDragActive, isDragReject])

  return (
    <div style={divStyle}>
      <div {...getRootProps({className: 'dropzone', style})}>
        <input {...getInputProps({name: 'image'})} />
        <p style={pStyle}>Drag 'n' drop an image here, or click to select image</p>
        <p style={pStyle}>Image size limit is 2 MB</p>
      </div>
    </div>
  )
}

export default ImageDropzone

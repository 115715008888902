import React, {useEffect} from 'react';
import './stylesheets/HomeProfile.css'

const HomeProfile = () => {
    return (
        <div className="home-profile-container">
            <div className="top">
                <p className="title">{process.env.REACT_APP_WEBSITE_NAME}</p>
            </div>
            <div className="main">
                <div className="leftIntro">
                    <p>
                        Welcome to the {process.env.REACT_APP_WEBSITE_NAME}! You can find several modules under the Courses page, with more on the way / in development.
                    </p>
                    <p>
                        This information presented on this platform is intended to improve the quality of care,
                        by filling in the gaps on the higher-risks aspects of caregiving.
                        These are not accredited courses, and are not meant to serve as a substitute for legitimate certifications.
                    </p>
                </div>
                <div className="rightIcon">
                    <img src={`${process.env.PUBLIC_URL}/CG_logo.png`} alt=""/>
                </div>
            </div>
        </div>
    );
};

export default HomeProfile;
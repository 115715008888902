// file   : Toasts.js
// purpose: contain toasts that are used as feedback in different components

import React from 'react'
import toast, { Toaster } from 'react-hot-toast';

export const successToast = (message) => toast.success(message) 
export const errorToast = (message) => toast.error(message)

export const DefaultToaster = () => {
  return (
    <Toaster 
      position='top-center'
      reverseOrder={false}
      toastOptions={{
        style: {
          maxWidth: '250px',
          zIndex: 5,
          fontFamily: '\'Roboto\', sans-serif',
          fontSize: '0.8rem'
        },
        duration: 2000
      }}
    />
  )
}
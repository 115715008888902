// file   : AdminEditModuleContent.js
// purpose: main parent component for update a module

import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { updateModule, selectCourse, setIsRefreshed } from '../../redux/coursesSlice'
import { selectIsActiveSideMenu } from '../../redux/sideMenuSlice'
import { processContent } from '../../S3Helper'
import './stylesheets/AdminCreateModuleContent.css'
import { API } from '../../API'
import { DefaultLoader } from '../Loaders'
import { successToast, errorToast } from '../Toasts'
import CreateEditModuleQuestion from './CreateEditModuleQuestion'
import CreateEditModuleImage from './CreateEditModuleImage'
import CreateEditModuleVideo from './CreateEditModuleVideo'
import CreateEditModuleArticle from './CreateEditModuleArticle'
import { convertFromRaw } from 'draft-js'
import BadgeTray from './BadgeTray'
import AlertDialog from '../AlertDialog'
import { STATUS_ALL } from "../Helper";
const AdminEditModuleContent = () => {
  const { courseid, moduleid } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const course = useSelector(state => selectCourse(state, courseid))
  const module = course.modules.find(m => m.id === parseInt(moduleid))
  const isActiveSideMenu = useSelector(selectIsActiveSideMenu)
  const [deleteIdx, setDeleteIdx] = useState(null)
  const [deleteMap, setDeleteMap] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [dialogStatus, setDialogStatus] = useState(false)
  const [moduleTitle, setModuleTitle] = useState(module.title)
  const [previewModuleTitle, setPreviewModuleTitle] = useState(false)
  const [moduleStatus, setModuleStatus] = useState(module.status)
  const [moduleNo, setModuleNo] = useState(module.module_no)
  const [threshold, setThreshold] = useState(module.threshold ? module.threshold : 80)
  const [badgeStyle, setBadgeStyle] = useState(module.badge_style)
  const [priority, setPriority] = useState(module.content[module.content.length-1].priority)
  const [allContent, setAllContent] = useState(null)
  let isActive = isActiveSideMenu ? 'admin-sidemenu-active' : null

  // default content state for article when initialised
  const initialContentState = {
    "blocks": [
        {
            "key": "637gr",
            "text": "",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "data": {}
        }
    ],
    "entityMap": {}
  }

  // file the module with previous module data
  useEffect(() => {
    const initialContentState = {
      "blocks": [
          {
              "key": "637gr",
              "text": "",
              "type": "unstyled",
              "depth": 0,
              "inlineStyleRanges": [],
              "entityRanges": [],
              "data": {}
          }
      ],
      "entityMap": {}
    }
    document.querySelector(`#status>[value=${module.status}]`).setAttribute('selected',"selected")
    let newContent = []
    let moduleContent = module.content
    for (let i = 0; i < moduleContent.length; i++) {
      let content = moduleContent[i]
      if (content.type === 'image') {
        newContent.push({
          id: content.id,
          priority: content.priority,
          type: content.type,
          url: content.url,
          file: null,
          archived: content.archived
        })
      } else if (content.type === 'video') {
        newContent.push({
          id: content.id,
          priority: content.priority,
          type: content.type,
          url: content.url,
          file: null,
          archived: content.archived
        })
      } else if (content.type === 'question') {
        newContent.push({
          id: content.id,
          type: content.type,
          priority: content.priority,
          body: content.body,
          option_A: content.option_A,
          option_B: content.option_B,
          option_C: content.option_C,
          option_D: content.option_D,
          correct_ans: content.correct_ans,
          archived: content.archived
        })
      } else if (content.type === 'article') {
        newContent.push({
          id: content.id,
          type: content.type,
          priority: content.priority,
          url: content.url,
          article: initialContentState,
          archived: content.archived
        })
      }
    }
    setAllContent(newContent)
    setModuleTitle(module.title)
    setPreviewModuleTitle(false)
    setModuleNo(module.module_no)
    setThreshold(module.threshold ? module.threshold : 80)
    setBadgeStyle(module.badge_style)
    setPriority(module.content[module.content.length-1].priority)
  }, [module])
  
  // add module component depends on the content type 
  const createModuleComponent = (content, index) => {
    let component = null
    // highlight the icon based on the module component
    const active = (type) => type === content.type ? 'icon-active' : null

    switch(content.type) {
      case 'image':
        component = (
          <CreateEditModuleImage
            key={content['id']}
            isSubmitting={isSubmitting}
            data={{allContent: allContent, index: index}}
            setAllContent={setAllContent}
          />
        )
        break
      case 'video':
        component = (
          <CreateEditModuleVideo
            key={content['id']}
            isSubmitting={isSubmitting}
            data={{allContent: allContent, index: index}}
            setAllContent={setAllContent}
          />
        )
        break
      case 'question':
          component = (
            <CreateEditModuleQuestion
              key={content['id']}
              isSubmitting={isSubmitting}
              data={{allContent: allContent, index: index}}
              setAllContent={setAllContent}
            />
          )
          break
      case 'article':
        component = (
          <CreateEditModuleArticle
            key={content['id']}
            isSubmitting={isSubmitting}
            data={{allContent: allContent, index: index}}
            setAllContent={setAllContent}
          />
        )
        break
      default:
        component = null
    }

    // for each component, there is an icon tray above the component allowing admin to 
    // switch the component type easily or delete the module component itself
    return (
      <div key={content['id']} className='component-container'>
        {<div>
          <div className={`component-icon-tray ${isSubmitting && 'icon-disabled'}`}>
            <i className='fas fa-trash' onClick={() => onDeleteClick(content, content['id'])}></i>
          </div>
        </div>}
        {component}
      </div>
    )
  }

  // handle the change in component when is changing the component type 
  const handleChangeComponent = (index, newType) => {
    const prio = allContent[index].priority
    if (newType === 'image') {
      allContent.splice(index, 1, {
        type: 'image',
        file: null,
        priority: prio
      })
    } else if (newType === 'video') {
      allContent.splice(index, 1, {
        type: 'video',
        file: null,
        url: '',
        priority: prio
      })
    } else if (newType === 'question') {
      allContent.splice(index, 1, {
        type: 'question',
        body: '', 
        option_A: '', option_B: '', option_C: '', option_D: '', 
        correct_ans: '', 
        priority: prio
      }) 
    } else if (newType === 'article') {
      allContent.splice(index, 1, {
        type: 'article',
        article: initialContentState,
        url: '',
        priority: prio
      })
    }
    setAllContent([...allContent])
  }

  // delete a module component
  const handleDelete = (index) => {
    setAllContent(allContent.filter(e => e['id'] !== index))
    deleteMap[index] = {...deleteMap[index],active: true}
    setDeleteMap(deleteMap)
    successToast('Deleted successfully')
    setDialogStatus(false)
  }

  const onDeleteClick = (content, id)=>{
    setDeleteIdx(id)
    deleteMap[id] = {...content, active:false}
    setDeleteMap(deleteMap)
    setDialogStatus(true)
  }

  // add a create/update image component
  const handleClickImage = () => {
    let newPriority = priority + 1
    setPriority(newPriority)
    setAllContent(allContent.concat({type: 'image', file: null, priority: newPriority, archived:false}))
  }

  // add a create/update video component
  const handleClickVideo = () => {
    let newPriority = priority + 1
    setPriority(newPriority)
    setAllContent(allContent.concat({type: 'video', file: null, url: '', priority: newPriority, archived:false}))
  }

  // add a create/update quiz component
  const handleClickQuestion = () => {
    let newPriority = priority + 1
    setPriority(newPriority)
    setAllContent(allContent.concat({
      type: 'question', 
      body: '', 
      option_A: '', option_B: '', option_C: '', option_D: '', 
      correct_ans: '', 
      priority: newPriority,
      archived:false
    }))
  }

  // add a create/update article component
  const handleClickArticle = () => {
    let newPriority = priority + 1
    setPriority(newPriority)
    setAllContent(allContent.concat({
      type: 'article',
      article: initialContentState,
      url: '',
      priority: newPriority,
      archived:false
    }))
  }

  // check if admin is needed to set the score threshold depends on
  // if he has created any quiz/question component
  const shouldSetThreshold = () => {
    for (let i = 0; i < allContent.length; i++) {
      if (allContent[i].type === 'question') {
        return true
      }
    }
    return false
  }

  // validate the input data for each component
  const validateInput = () => {
    // validate the score threshold
    if (isNaN(threshold) || threshold === '') {
      errorToast('Threshold should be a number')
      return false
    } else {
      if (threshold < 0 || threshold > 100) {
        errorToast('Threshold should be 0 - 100')
        return false
      }
    }

    // validate the module title and check if module has content
    if (moduleTitle === '' || allContent.length === 0) {
      return false
    }

    for (let i = 0; i < allContent.length; i++) {
      let content = allContent[i]
      // validate any question/quiz component
      if (content.type === 'question') {
        if (content.body === '' 
          || content.option_A === '' 
          || content.option_B === '' 
          || content.option_C === '' 
          || content.option_D === '' 
          || content.correct_ans === '') {
            return false
        }
      // validate any video component
      } else if (content.type === 'video') {
        if (content.file === null && content.url === '') {
          return false
        }
      // validate any image component
      } else if (content.type === 'image') {
        if (content.file === null && content.url === '') {
          return false
        }
      // validate any article component
      } else if (content.type === 'article') {
        const cstate = convertFromRaw(content.article)
        if (!cstate.hasText() || cstate.getPlainText() === '') {
          return false
        }
      }
    }
    return true
  }

  const handleSubmit = () => {
    setIsLoading(true)
    setIsSubmitting(true)
    if (validateInput()) {
      if (!shouldSetThreshold()) {
        setThreshold(-1)
      }

      // process the content, uploading files to s3 and update content with s3 file url 
      processContent(allContent)
      .then(newContent => {
        let data = {
          title: moduleTitle,
          course_id: course.id,
          module_no: moduleNo,
          threshold: threshold,
          badge_style: badgeStyle,
          status:moduleStatus,
          content: newContent
        }
        let reqDeleteData = Object.fromEntries(Object.entries(deleteMap).filter(([k,v])=> v['active']))
        API.archive_content(reqDeleteData)
        .then(res => {
          // stores the module data in server and redirect to admin home page
          API.editModule(moduleid, data)
          .then(res => {
            successToast('The module is updated')
            setIsLoading(false)
            dispatch(setIsRefreshed(false))
            history.push(`/admin`)
          })
        })
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
        setIsSubmitting(false)
      })
    } else {
      setIsLoading(false)
      setIsSubmitting(false)
      errorToast('Required information is missing')
    }
  }

  const changeStatus = (value) => {
    dispatch(updateModule({courseId: course.id, moduleId: module.id, fieldName:'status', fieldValue:value}))
    // update the module status in server
    API.updateModuleStatus(module.id, value)
    .then(res => {
      successToast('The module is updated')
      setIsLoading(false)
      setIsSubmitting(false)
      dispatch(setIsRefreshed(false))
    })
  }
  return (
    <div className={'create-modulecontent-container ' + (isActive)}>
      <div className='create-modulecontent-main'>
        <AlertDialog status={dialogStatus} onConfirm={() => handleDelete(deleteIdx)} onCancel={() => setDialogStatus(false)} title={"Alert"} description={"Are you sure you want to delete this?"}></AlertDialog>
        <div className='create-modulecontent-header'>
          <p className='create-modulecontent-header-title'>
            Edit Module
          </p>
          <p className='create-modulecontent-course-title'>
            Course: {course.course_name}
          </p>
          <div className='create-modulecontent-module-title'>
            <p> 
              {previewModuleTitle && moduleTitle}
            </p>
            {!previewModuleTitle && 
              <input 
                type='text'
                disabled={isSubmitting}
                value={moduleTitle}
                placeholder="What's the module title?"
                onChange={e => setModuleTitle(e.target.value)} 
              />
            }
            <i 
              className='fas fa-pen'
              onClick={() => setPreviewModuleTitle(!previewModuleTitle)}
            >
            </i>
          </div>
          <div className="selectStatus">
            <label>Module Status: </label>
            <select name='module_status' id='status' onChange={e => { changeStatus(e.target.value) }}>
              <option value ={STATUS_ALL.PUBLISHED}>{STATUS_ALL.PUBLISHED}</option>
              <option value ={STATUS_ALL.ARCHIVED}>{STATUS_ALL.ARCHIVED}</option>
              <option value ={STATUS_ALL.DRAFT}>{STATUS_ALL.DRAFT}</option>
            </select>
          </div>
          
        </div>
        {allContent && allContent.map((e, idx) => {
          if (e['archived'] == 0){
            return createModuleComponent(e ,idx)
          }
        })}
        {!isSubmitting && <div className='addcontent-container'>
          <p className='addcontent-title'>Add Content</p>
          <div className='addcontent-icon-tray'>
            <i className="fas icon fa-2x fa-image" onClick={handleClickImage}></i>
            <i className="fas icon fa-2x fa-video" onClick={handleClickVideo}></i>
            <i className="fas icon fa-2x fa-question" onClick={handleClickQuestion}></i>
            <i className="fas icon fa-2x fa-newspaper" onClick={handleClickArticle}></i>
          </div>
        </div>}
        <div style={{width: '90%', maxWidth: '400px', margin: '1rem auto'}}>
          <label style={{margin: '0 auto 0 auto'}}>Score threshold to obtain badge:</label>
          <p style={{color: 'grey', fontSize: '0.8rem', margin: '0.5rem auto'}}>If no question exists in the module, threshold will be set to -1.</p>
          <input
            type='number'
            placeholder='0 - 100. Eg: thresh=80, 80% will get the badge'
            value={threshold}
            step={1}
            min={0}
            max={100}
            onChange={e => setThreshold(e.target.value)}
          />
        </div>
        <BadgeTray 
          isSubmitting={isSubmitting}
          setBadgeStyle={(num) => setBadgeStyle(num)}
          badgeStyle={badgeStyle}
        />
        <div className='create-modulecontent-button-container'>
          {!isLoading && <button className='create-modulecontent-button' disabled={isSubmitting} onClick={handleSubmit}>{'Update'}</button>}
          <DefaultLoader isLoading={isLoading} />
        </div>
      </div>
    </div>
  )
}

export default AdminEditModuleContent

// file   : Header.js
// purpose: renders the navigation menu and side menu

import React from 'react'
import './stylesheets/Header.css'
import { useSelector, useDispatch } from 'react-redux'
import { selectCurrentAuth, selectCurrentUser } from '../redux/currentUserSlice'
import { activeSideMenu } from '../redux/sideMenuSlice'
import HeaderMenu from './HeaderMenu'
import SideMenu from './SideMenu'
import { useLocation } from 'react-router-dom'

const Header = () => {
  const user = useSelector(selectCurrentUser)
  const isLoggedIn = useSelector(selectCurrentAuth);
  const dispatch = useDispatch()
  const isInModulePage = useLocation().pathname.match(/^\/course/) ? true : false
  const isInAdminEditPage = useLocation().pathname.match(/^\/admin\/editmodule/) ? true : false
  const isInAdminCreatePage = useLocation().pathname.match(/^\/admin\/createmodule/) ? true : false
  const isAdmin = user && (user.privilege === 'admin')
  const isEnabledSidemenu = isInModulePage || isInAdminEditPage || isInAdminCreatePage

  const handleClick = () => {
    if (isEnabledSidemenu) {
      dispatch(activeSideMenu())
    }
  }

  return (
    <div className='header'>
      {isEnabledSidemenu&& <SideMenu />}
      <p 
        className='header-title' 
        onClick={handleClick}
      >
        {!isAdmin && (isEnabledSidemenu ? <i className='fas fa-bars fa-lg header-sidemenu-icon'></i> : 'Capital Guardians')}
        {isAdmin && (isEnabledSidemenu ? <i className='fas fa-bars fa-lg header-sidemenu-icon'></i> : 'Admin Portal')}
      </p>
      <HeaderMenu user={user || {}} isAdmin={isAdmin} isLoggedIn={isLoggedIn} />
    </div>
  )
}

export default Header
// file   : moduleResultsSlice.js
// purpose: redux slice managing states of module results of current user

import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  moduleResults: null
}

export const moduleResultsSlice = createSlice({
  name: 'currentModuleResults',
  initialState,
  reducers: {
    setModuleResults: (state, action) => {
      state.moduleResults = action.payload
    },
    updateModuleResult: (state, action) => {
      for (let i = 0; i < state.moduleResults.length; i++) {
        if (state.moduleResults[i].module_id === action.payload.module_id) {
          state.moduleResults[i] = action.payload
          break;
        }
      }
    },
    addModuleResult: (state, action) => {
      state.moduleResults.push(action.payload)
    }
  }
})

export const {
  setModuleResults,
  updateModuleResult,
  addModuleResult
} = moduleResultsSlice.actions

export const selectModuleResults = state => state.currentModuleResults.moduleResults
export const selectModuleResult = (state, moduleId) => {
  return (state.currentModuleResults.moduleResults || []).find(e => e.module_id === moduleId)
}

export default moduleResultsSlice.reducer
// file   : CreateEditModuleQuestion.js
// purpose: create or update quiz element in module

import React, { useEffect, useState } from 'react'
import './stylesheets/CreateEditModuleQuestion.css' 

const divStyle = {
  maxWidth: '400px',
  width: '90%',
  margin: '0 auto'
}

const answerDivStyle = {
  display: 'flex',
  justifyContent: 'center',
}

const CreateEditModuleQuestion = (props) => {
  const { data, setAllContent, isSubmitting } = props
  const { allContent, index } = data
  const [body, setBody] = useState('')
  const [optionA, setOptionA] = useState('')
  const [optionB, setOptionB] = useState('')
  const [optionC, setOptionC] = useState('')
  const [optionD, setOptionD] = useState('')
  const [correntAns, setCorrectAns] = useState('')

  const active = (ans) => ans === correntAns ? 'ans-active' : null

  useEffect(() => {
      setBody(allContent[index].body)
      setOptionA(allContent[index].option_A)
      setOptionB(allContent[index].option_B)
      setOptionC(allContent[index].option_C)
      setOptionD(allContent[index].option_D)
      setCorrectAns(allContent[index].correct_ans)
  }, [allContent, index])

  // update content in parent component based on field name
  const handleInputChange = (value, field) => {
    if (field === 'body') {
      allContent[index].body = value
      setAllContent(allContent)
      setBody(value)
    } else if (field === 'option_A') {
      allContent[index].option_A = value
      setAllContent(allContent)
      setOptionA(value)
    } else if (field === 'option_B') {
      allContent[index].option_B = value
      setAllContent(allContent)
      setOptionB(value)
    } else if (field === 'option_C') {
      allContent[index].option_C = value
      setAllContent(allContent)
      setOptionC(value)
    } else if (field === 'option_D') {
      allContent[index].option_D = value
      setAllContent(allContent)
      setOptionD(value)
    }  else if (field === 'correct') {
      allContent[index].correct_ans = value
      setAllContent(allContent)
      setCorrectAns(value)
    }
  }

  return (
    <div style={divStyle}>
      <label>Question:</label>
      <input
        type='text'
        disabled={isSubmitting}
        placeholder="Question Body"
        value={body}
        onChange={(e) => handleInputChange(e.target.value, 'body')}
      />
      <label>Options:</label>
      <input
        type='text'
        disabled={isSubmitting}
        placeholder="Option A"
        value={optionA}
        onChange={(e) => handleInputChange(e.target.value, 'option_A')}
      />
      <input
        type='text'
        disabled={isSubmitting}
        placeholder="Option B"
        value={optionB}
        onChange={(e) => handleInputChange(e.target.value, 'option_B')}
      />
      <input
        type='text'
        disabled={isSubmitting}
        placeholder="Option C"
        value={optionC}
        onChange={(e) => handleInputChange(e.target.value, 'option_C')}
      />
      <input
        type='text'
        disabled={isSubmitting}
        placeholder="Option D"
        value={optionD}
        onChange={(e) => handleInputChange(e.target.value, 'option_D')}
      />
      <label>Correct Answer:</label>
      <div style={answerDivStyle} className={isSubmitting ? `disabled-div` : undefined}>
        <p className={`option-ans ${active('a')}`} onClick={() => handleInputChange('a', 'correct')}>A</p>
        <p className={`option-ans ${active('b')}`} onClick={() => handleInputChange('b', 'correct')}>B</p>
        <p className={`option-ans ${active('c')}`} onClick={() => handleInputChange('c', 'correct')}>C</p>
        <p className={`option-ans ${active('d')}`} onClick={() => handleInputChange('d', 'correct')}>D</p>
      </div>
    </div>
  )
}

export default CreateEditModuleQuestion
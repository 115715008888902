// file   : Auth.js
// purpose: contains functions that deals with authentication and authorization

import jwt_decode from 'jwt-decode'
import { API } from './API'

// decode jwt token
const decodeJwt = (token) => {
  const decoded = jwt_decode(token)
  return decoded
}

// check if token is expired
const isNotExpiredToken = (token) => {
  const decoded = decodeJwt(token)
  return Date.now() < decoded.exp * 1000
}

// check if token is verified
const isVerifiedToken = async (token) => {
  const res = await API.verifyToken({ token: token })
  return res.status === 200
}

// get access and refresh tokens from localstorage
const getTokens = () => {
  const accessToken = window.localStorage.getItem('access')
  const refreshToken = window.localStorage.getItem('refresh')
  return { accessToken, refreshToken }
}

// refresh the tokens using refresh token
const refreshTokens = async (refreshToken) => {
  const res = await API.refreshToken({refresh: refreshToken})
  const { access, refresh } = res.data
  window.localStorage.setItem('access', access)
  window.localStorage.setItem('refresh', refresh)
}

// check if access and refresh tokens are valid
// the process of checking validity of tokens are as below: 
// if access and refresh token exists
// -- if access token is not expired
// ---- if access token is verified, return true
// ---- else, return false
// -- else if access token is expired
// ---- if refresh token is not expired
// ------ if refresh token is verified
// -------- refresh the tokens and return true
// ------ else, return false
// ---- else, return false
// else if only refresh token is available
// -- if refresh token is not expired
// ---- if refresh token is verified
// ------ refresh the tokens and return true
// ---- else, return false
// -- else, return false
// else (for other cases), return false
const areValidTokens =  (accessToken, refreshToken) => {
  if (accessToken && refreshToken) {
    if(isNotExpiredToken(accessToken)) {
      console.log('access not expired')
      if(isVerifiedToken(accessToken)) {
        return true
      } else {
        return false
      }
    } else {
      if(isNotExpiredToken(refreshToken)) {
        console.log('refresh not expired')
        if(isVerifiedToken(refreshToken)) {
          console.log('refresh is verified')
          refreshTokens(refreshToken)
          return true
        } else {
          console.log('refresh is not verified')
          return false
        }
      } else {
        return false
      }
    }
  } else if (!accessToken && refreshToken) {
    if(isNotExpiredToken(refreshToken)) {
      console.log('refresh not expired')
      if(isVerifiedToken(refreshToken)) {
        console.log('refresh is verified')
        refreshTokens(refreshToken)
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  } else {
    return false
  }
}

export {
  decodeJwt,
  isNotExpiredToken,
  isVerifiedToken,
  getTokens,
  areValidTokens
}


























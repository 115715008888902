// file   : CreateEditModuleArticle.js
// purpose: create or update rich text article in module

import axios from 'axios'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './stylesheets/CreateEditModuleArticle.css'

const divStyle = {
  width: '90%',
  margin: '0 auto'
}

const editorStyle = {
  border: '1px solid darkblue',
  borderRadius: '8px',
  padding: '0.5rem'
}

const CreateEditModuleArticle = (props) => {
  const { data, setAllContent } = props
  const { allContent, index } = data
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  // if it's editing article, fetch json from s3 url and convert it to editorstate
  // else create editorstate with default contentstate
  useEffect(() => {
    if (allContent[index].url) {
      axios 
        .get(allContent[index].url)
        .then(res => {
          allContent[index].article = res.data
          allContent[index].url = ''
          setAllContent(allContent)
          const contentState = convertFromRaw(res.data)
          setEditorState(EditorState.createWithContent(contentState))
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      const contentState = convertFromRaw(allContent[index].article)
      setEditorState(EditorState.createWithContent(contentState))
    }
  }, [index, setAllContent, allContent])

  useEffect(() => {
    const cstate = convertFromRaw(allContent[index].article)
    if (cstate.hasText() || cstate.getPlainText()) {
      setEditorState(EditorState.createWithContent(cstate))
    } 
  }, [allContent, index])

  // update content in parent component whenever article is updated
  const handleEditorStateChange = (e) => {
    setEditorState(e)
    allContent[index].article = convertToRaw(e.getCurrentContent())
    setAllContent(allContent)
  }

  // available toolbar options for react-draft editor
  const toolbarOptions = {
    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'remove', 'history']
  }
  
  return (
    <div style={divStyle}>
      <Editor
        wrapperClassName="demo-wrapper"
        editorState={editorState}
        editorStyle={editorStyle}
        toolbar={toolbarOptions}
        onEditorStateChange={e => handleEditorStateChange(e)}
      />
    </div>
  )
}

export default CreateEditModuleArticle
// file   : BadgeTray.js
// purpose: renders all badges to be chosen in module editing and creation page

import React from 'react'
import { getBadge } from './AdminHelper'
import './stylesheets/BadgeTray.css'

const BadgeTray = (props) => {
  const {isSubmitting,  setBadgeStyle, badgeStyle} = props
  
  const isSubmittingClass = (isSubmitting) => {
    if (isSubmitting) {
      return 'submitting'
    } else {
      return null
    }
  }

  return (
    <div className={`badge-tray-container ${isSubmittingClass(isSubmitting)}`}>
      <label style={{margin: '0 auto 0 auto'}}>Choose a badge:</label>
      <div className='badge-tray'>
        {[...Array(8)].map((e, i) => {
          return getBadge(
            i + 1, 
            () => setBadgeStyle(i + 1), 
            num => parseInt(badgeStyle) === num ? 'badge-active' : null 
          )
        })}
      </div>
    </div>
  )
}

export default BadgeTray
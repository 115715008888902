// file   : ModuleModal.js
// purpose: modal that shows the result user has achieved in the module

import React from 'react'
import './stylesheets/ModuleModal.css'
import { useSelector } from 'react-redux'
import { selectIsActiveSideMenu } from '../redux/sideMenuSlice'
import { Link } from 'react-router-dom'

const ModuleModal = (props) => {
  // center itself depends on whether sidemenu is active or not
  const isActiveSideMenu = useSelector(selectIsActiveSideMenu)
  let isActive = isActiveSideMenu ? 'sidemenu-active' : null

  const { threshold, result, setShowModal } = props
  const { score, moduleTitle, numCorrect, numQuestions } = result
  // returns true if user has passed the score threshold set for the module
  const isPassed = score !== -1 ? score >= threshold : true  

  return (
    <div className={'modal ' + (isActive)}>
      <div className={'modal-content ' + (isActive)}>
        {isPassed && <p className='modal-title'>Congratulations!</p>}
        {isPassed ? <p>You have completed this module:</p> : <p>{`Please try again to reach ${threshold}%.`}</p>}
        <p style={{fontWeight: 'bolder'}}>{moduleTitle}</p>
        {score !== -1 && 
          <div>
            <p>{`Your score: ${score}%`}</p>
            <p>{`Answered correctly: ${numCorrect}/${numQuestions}`}</p>
          </div>
        }      
        <div className='modal-close-button-container'>
          {isPassed ? <Link to={'/'} className="modal-close-button"><span>Learner Home</span></Link> : <button className='modal-close-button' onClick={() => setShowModal(false)}>Close</button> }
        </div>
      </div>
    </div>
  )
}

export default ModuleModal
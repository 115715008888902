// file   : EmailResetPasswordForm.js
// purpose: a form for resetting password when user forgot his password

import React, {useState, useRef} from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import './stylesheets/Form.css'
import { errorMessage, commonLabel } from './CommonComponents'
import { Link } from 'react-router-dom'
import { DefaultLoader } from './Loaders'
import { API } from '../API'

const EmailResetPasswordForm = () => {
  const [isSentLink, setIsSentLink] = useState(false)
  const [isFirstSuccessResponse, setIsFirstSuccessResponse] = useState(false)
  const [isSecondSuccessResponse, setIsSecondSuccessResponse] = useState(false)
  const [isFirstFailedResponse, setIsFirstFailedResponse] = useState(false)
  const [isSecondFailedResponse, setIsSecondFailedResponse] = useState(false)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isFirstLoading, setIsFirstLoading] = useState(false)
  const [isSecondLoading, setIsSecondLoading] = useState(false)

  const formRef = useRef();

  // validation schema for when user enters email to receive security code
  const sendLinkValidationSchema = Yup.object({
    email: Yup.string()
          .required('Email is required')
          .email('Invalid email address')
  })

  // validation schema for when user enters security code and new password
  const submitValidationSchema = Yup.object({
    email: Yup.string()
      .required('Email is required')
      .email('Invalid email address'),      
    password: Yup.string()
      .required('New password is required')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/,
        '8 - 16 characters, at least one letter and one number' 
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords much match')
      .required('Confirming password is required'),    
    code:  Yup.string()
      .required('Verification code is required')
      .min(6, 'Must be exactly 6 characters')
      .max(6, 'Must be exactly 6 characters')
      .matches( 
        /^[a-zA-Z0-9]*$/,
        'Must only contain alphanumerics'
      )
  })

  const handleSubmit = (values) => {
    setIsSubmitting(true)
    setIsFirstFailedResponse(false)
    setIsSecondFailedResponse(false)

    if (isSentLink) {
      setIsSecondLoading(true)
      API.submitForgotPassword({
        email: values.email,
        newPassword: values.password,
        verificationCode: values.code
      })
      .then(res => {
        setIsSecondLoading(false)
        setIsSubmitting(true)
        setIsSecondSuccessResponse(true)
      })
      .catch(err => {
        setIsSecondLoading(false)
        setIsSubmitting(false)
        setIsSecondFailedResponse(true)
      })
    } else {
      setIsFirstLoading(true)
      API.sendResetPasswordEmail({email: values.email})
      .then(res => {
        setIsFirstLoading(false)
        setIsFirstSuccessResponse(true)
        setIsSentLink(true)
        setIsSubmitting(false)
      })
      .catch(err => {
        console.log(err.response)
        setIsFirstLoading(false)
        setIsSubmitting(false)
        setIsFirstFailedResponse(true)
      })
    }
  }

  return (
    <Formik 
      innerRef={formRef}
      initialValues={{
        email: '',
        password: '',
        confirmPassword: '',
        code: ''
      }}
      validationSchema={
        isSentLink ? submitValidationSchema : sendLinkValidationSchema 
      }
      onSubmit={(values, actions) => {
        handleSubmit(values, actions)
      }}
    >
      <div className='form-container'>
        <p className='form-container-title'>Reset your Password</p>
        <p className='form-container-description'>
          A security code will be sent to your email for resetting new password 
        </p>
        <Form>
          {commonLabel('email', 'Email')}
          <Field
            name='email'
            type='email'
            disabled={isSentLink || isSubmitting}
            placeholder='Enter your email address'
          />
          {errorMessage('email')}

          {isFirstFailedResponse && 
            <p className='form-failed-message'>
              Error occured when sending security code to email.
            </p>
          }
          {!isFirstLoading && !isFirstSuccessResponse && 
            <input disabled={isSentLink} type="submit" value="Send code to email" />
          }
          <DefaultLoader isLoading={isFirstLoading} />
          {isFirstSuccessResponse && 
            <p className='form-success-message'>
              Security code has been sent to your email.
            </p>
          }

          {commonLabel('password', 'New Password')}
          <Field 
            name='password'
            type='password'
            disabled={!isSentLink || isSubmitting}
            placeholder='Enter your new password'
          /> 
          {errorMessage('password')}

          {commonLabel('confirmPassword', 'Confirm password')}
          <Field 
            name='confirmPassword'
            type='password'
            disabled={!isSentLink || isSubmitting}
            placeholder='Enter your password again'
          /> 
          {errorMessage('confirmPassword')}

          {commonLabel('code', 'Security Code')}      
          <Field
            name='code'
            disabled={!isSentLink || isSubmitting}
            placeholder='Enter security code'
          />
          {errorMessage('code')}

          {isSecondFailedResponse && 
            <p className='form-failed-message'>
              Error occured when submitting.
            </p>
          }
          {!isSecondLoading && !isSecondSuccessResponse &&
            <input disabled={!isSentLink} type="submit" value="Reset Password" />  
          }
          <DefaultLoader isLoading={isSecondLoading} />
          {isSecondSuccessResponse && 
            <p className='form-success-message'>
              Your password has been changed.
            </p>
          }
          <Link to='/login' className='link-text'>
            Return to login
          </Link>
        </Form>
      </div>
    </Formik>
  )
}

export default EmailResetPasswordForm
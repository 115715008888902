// file   : VideoDropzone.js
// purpose: a dropzone to drop or attach any video file

import React, { useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { 
  divStyle,
  baseStyle,
  activeStyle,
  acceptStyle,
  rejectStyle,
  pStyle 
} from './DropzoneStyle'

const VideoDropzone = (props) => {
  // set up the dropzone to accept only one video file at a time
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: 'video/*',
    maxFiles: 1,
    multiple: false,
    disabled: props.isSubmitting,
    onDropAccepted: (files) => { props.setVideo(files[0])}
  })

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [isDragAccept, isDragActive, isDragReject])

  return (
    <div style={divStyle}>
      <div {...getRootProps({className: 'dropzone', style})}>
        <input {...getInputProps({name: 'video'})} />
        <p style={pStyle}>Drag 'n' drop a video here, or click to select video</p>
      </div>
    </div>
  )
}

export default VideoDropzone
// file   : Footer.js
// purpose: renders footer for some of the pages

import React from 'react'
import './stylesheets/Footer.css'

const Footer = () => {
  return (
    <div className='footer'>
      <p className='footer-title'>Capital Guardians Academy</p>
      <p className='footer-copyright'>Copyright © 2021 Capital Guardians. All rights reserved.</p>
    </div>
  )
}

export default Footer

// file   : ModuleArticle.js
// purpose: rich text article element for module content

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { EditorState, convertFromRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import './stylesheets/ModuleArticle.css'

const ModuleArticle = (props) => {
  const [ editorState, setEditorState ] = useState(EditorState.createEmpty())
  
  // get the json data from s3 and convert it to renderable EditorState
  useEffect(() => {
    axios
      .get(props.articleUrl)
      .then(res => {
        const contentState = convertFromRaw(res.data)
        setEditorState(EditorState.createWithContent(contentState))
      })
      .catch(err => {
        if (err) console.log(err)
      })
  }, [props.articleUrl])
  

  return (
    <div className='modulearticle-container'> 
      <Editor editorState={editorState} toolbarHidden={true}  readOnly={true} />
    </div>
  )
}

export default ModuleArticle


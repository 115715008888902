// file   : AdminHelper.js
// purpose: contains all the helper functions needed for admin components

import React from 'react'
// get the badge icon based on number and can pass in onclick functions and active classname
export const getBadge = (num, onclick, active) => {
  let icon = null
  if (num === 1) {
    icon = <i key={num} className={`fas fa-cat ${active(1)}`} onClick={onclick}></i>
  } else if (num === 2) {
    icon = <i key={num} className={`fas fa-dog ${active(2)}`} onClick={onclick}></i>
  } else if (num === 3) {
    icon = <i key={num} className={`fas fa-feather-alt ${active(3)}`} onClick={onclick}></i>
  } else if (num === 4) {
    icon = <i key={num} className={`fas fa-paw ${active(4)}`} onClick={onclick}></i>
  } else if (num === 5) {
    icon = <i key={num} className={`fas fa-ghost ${active(5)}`} onClick={onclick}></i>
  } else if (num === 6) {
    icon = <i key={num} className={`fas fa-ice-cream ${active(6)}`} onClick={onclick}></i>
  } else if (num === 7) {
    icon = <i key={num} className={`fas fa-snowman ${active(7)}`} onClick={onclick}></i>
  } else if (num === 8) {
    icon = <i key={num} className={`fas fa-robot ${active(8)}`} onClick={onclick}></i>
  }
  return icon
}

export const getTick = () => {
  let icon = <i className={`fas fa-solid fa-check`}></i>
  return icon
}

export const getEdit = () => {
  let icon = <i className={`fas fa-solid fa-pen`}></i>
  return icon
}
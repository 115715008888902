// file   : Helper.js
// purpose: contains all the helper functions to be used in other components

import avt1 from '../assets/avatar1.svg'
import avt2 from '../assets/avatar2.svg'
import avt3 from '../assets/avatar3.svg'
import avt4 from '../assets/avatar4.svg'
import avt5 from '../assets/avatar5.svg'
import avt6 from '../assets/avatar6.svg'
import avt7 from '../assets/avatar7.svg'
import avt8 from '../assets/avatar8.svg'

export const getAvatar = (num) => {
  const avatars = [avt1, avt2, avt3,avt4,avt5,avt6,avt7,avt8]
  return avatars[num-1]
}

export const STATUS_ALL = {
  PUBLISHED: "published", 
  DRAFT: "draft", 
  ARCHIVED: "archived"
};

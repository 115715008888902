// file   : LoginPage.js
// purpose: shows login form to login to onboarding portal

import React from 'react'
import Header from '../../components/Header'
import LoginForm from '../../components/LoginForm'
import Footer from '../../components/Footer'
import { useSelector } from 'react-redux'
import { selectCurrentAuth, selectCurrentisAdmin } from '../../redux/currentUserSlice'
import { Redirect } from 'react-router'

const LoginPage = () => {
  const isLoggedIn = useSelector(selectCurrentAuth)
  const isAdmin = useSelector(selectCurrentisAdmin)

  if (isLoggedIn && isAdmin) return <Redirect to='/admin' />
  if (isLoggedIn && !isAdmin) return <Redirect to='/' />

  return (
    <div>
      <Header /> 
      <LoginForm />
      <span style={{marginBottom: '5rem', display: 'block'}}></span>
      <Footer />
    </div>
  )
}

export default LoginPage
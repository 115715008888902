// file  :  AchievementPage.js
// purpose: Page that shows module completed by the user

import React from 'react'
import Header from '../../components/Header'
import Achievement from '../../components/Achievement'
import Footer from '../../components/Footer'
import { useCheckToken } from '../../components/CustomHooks'

const AchievementPage = () => {
  useCheckToken()

  return (
    <div>
      <Header /> 
      <Achievement />
      <span style={{marginBottom: '5rem', display: 'block'}}></span>
      <Footer />
    </div>
  )
}

export default AchievementPage
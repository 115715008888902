// file   : HeaderMenu.js
// purpose: contains nav items that can redirect user to other pages

import React, { useState, useEffect, createRef } from 'react'
import { getAvatar } from './Helper'
import './stylesheets/HeaderMenu.css'
import { Link, NavLink } from 'react-router-dom'
import { useWindowSize, useClickOutside } from './CustomHooks'
import { useDispatch } from 'react-redux'
import { authLogout } from '../redux/currentUserSlice'

const HeaderMenu = (props) => {
  const dispatch = useDispatch()
  const { isAdmin, isLoggedIn, user } = props
  const { first_name, last_name, avatar } = user

  const [isActive, setIsActive] = useState(false)
  const [width] = useWindowSize()
  const headerMenuRef = createRef()
  useClickOutside(headerMenuRef, () => setIsActive(false))
  const activeDropdownContent = isActive ? null : 'header-dropdown-content-hidden'
  const headerUserName = (first_name + last_name).length > 14 ? first_name : (first_name + ' ' + last_name)
  const homeLink = isAdmin ? '/admin' : '/'
  const homeContentLink = '/content'
  const profileLink = isAdmin ? '/admin/profile' : '/profile'
  const avatarSvg = user && (avatar ? getAvatar(parseInt(avatar)) : getAvatar(1))

  // if browser width is more than 650, expand the navigation menu 
  useEffect(() => {
    if (width > 650) {
      setIsActive(false)
    }
  }, [width])

  if (!isLoggedIn) {
    return (
      <div className='header-dropdown' ref={headerMenuRef}>
        <NavLink exact={true} to={`/login`} className={activeDropdownContent}  activeClassName='active-header-link'>Login</NavLink>
        <NavLink exact={true} to={homeContentLink} className={activeDropdownContent}  activeClassName='active-header-link' >Courses</NavLink>
      </div>
    )
  }

  return (
    <div className='header-dropdown' ref={headerMenuRef}>
      <div className={'header-user ' + (isActive ? 'header-user-active' : null)} onClick={() => setIsActive(!isActive)}>
        <img src={avatarSvg} className='header-user-avatar' alt='avatar' />
        <p className='header-user-name'>{headerUserName}</p>
      </div>
      
      <div className={isActive ? 'header-dropdown-content' : null}>
        <NavLink exact={true} to={homeLink} className={activeDropdownContent}  activeClassName='active-header-link' >Home</NavLink>
        <NavLink exact={true} to={homeContentLink} className={activeDropdownContent}  activeClassName='active-header-link' >Courses</NavLink>
        {props.isAdmin &&
          <NavLink exact={true} to={`/allusers`} className={activeDropdownContent}  activeClassName='active-header-link'>Users</NavLink>
        }
        <NavLink exact={true} to={profileLink} className={activeDropdownContent}  activeClassName='active-header-link'>Profile</NavLink>
        <Link to='/login' className={activeDropdownContent} onClick={() => {
          dispatch(authLogout())
          localStorage.clear()
        }}>
          Log out
        </Link>
      </div>
    </div>
  )
}

export default HeaderMenu
// file   : DeleteModal.js
// purpose: modals to allow admin to confirm or cancel delete module action

import React from "react";
import { ShortModal } from "../Modals";
import "./stylesheets/DeleteModal.css";
import { API } from "../../API";
import { useDispatch } from "react-redux";
import { removeModule, setIsRefreshed } from "../../redux/coursesSlice";
import { successToast } from "../Toasts";

const DeleteModal = (props) => {
  const dispatch = useDispatch();
  const { showModal, setShowModal, courseId, targetId, targetType } = props;

  const handleDelete = () => {
    switch (targetType) {
      case "course":
        API.deleteCourse(targetId)
          .then((res) => {
            console.log(res.data);
            dispatch(setIsRefreshed(false));
            successToast(`Deleted Course`);
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      case "module":
        API.deleteModule(targetId)
          .then((res) => {
            successToast("Deleted Module");
            dispatch(removeModule({ courseId: courseId, moduleId: targetId }));
            setShowModal(false);
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      default:
        break;
    }
  };

  return (
    <ShortModal isOpen={showModal}>
      <div className="modal-clsbutton-container">
        <button className="modal-clsbutton" onClick={() => setShowModal(false)}>
          X
        </button>
      </div>
      <p className="delete-modal-text">{`Do you want to delete this ${targetType}?`}</p>
      <div className="delete-modal-buttons">
        <button
          type="button"
          className="yes-button"
          onClick={handleDelete}
        >
          Yes
        </button>
        <button
          type="button"
          className="no-button"
          onClick={() => setShowModal(false)}
        >
          No
        </button>
      </div>
    </ShortModal>
  );
};

export default DeleteModal;

// file   : CreateEditModuleImage.js
// purpose: create or update image element in module

import React, { useEffect, useState } from 'react'
import ImageDropzone from '../ImageDropzone'

const CreateEditModuleImage = (props) => {
  const { data, setAllContent, isSubmitting } = props
  const { allContent, index } = data
  const [imageSrc, setImageSrc] = useState(null)

  useEffect(() => {
    if (allContent[index].url === undefined) {
      setImageSrc(allContent[index].file ? URL.createObjectURL(allContent[index].file) : null)
    } else {
      setImageSrc(allContent[index].url)
    }
  }, [allContent, index])

  const previewStyle = {
    display: 'block',
    width: '90%',
    height: 'auto',
    margin: '0 auto',
    maxWidth: '590px',
  }

  // update image file in parent component 
  const handleFileDrop = (image) => {
    allContent[index].file = image
    setAllContent(allContent)
    setImageSrc(URL.createObjectURL(image))
  }

  return (
    <div>
      <ImageDropzone setImage={(image) => handleFileDrop(image)} isSubmitting={isSubmitting} />
      {imageSrc && (
        <div>
          <p style={{textAlign: 'center', margin: '0.5rem auto'}}>Image Preview:</p>
          <img style={previewStyle} src={imageSrc} alt='preview' />
        </div>
      )}
    </div>
  )
}

export default CreateEditModuleImage
// file   : EditProfilePage.js
// purpose: page to edit profile details of user/admin

import React from 'react'
import Header from '../../components/Header'
import EditProfile from '../../components/EditProfile'
import Footer from '../../components/Footer'
import { useCheckToken } from '../../components/CustomHooks'

const EditProfilePage = () => {
  useCheckToken()

  return (
    <div>
      <Header /> 
      <EditProfile />
      <span style={{marginBottom: '5rem', display: 'block'}}></span>
      <Footer />
    </div>
  )
}

export default EditProfilePage
// file   : AdminAllUserPage.js
// purpose: display all user information including module results and uses toasts for feedback

import React from 'react'
import Header from '../../components/Header'
import AllUsersTable from '../../components/admin/AllUsersTable'
import { useCheckToken } from '../../components/CustomHooks'
import { DefaultToaster } from '../../components/Toasts'

const AdminAllUsersPage = () => {
  useCheckToken()

  return (
    <div>
      <Header />
      <DefaultToaster />
      <AllUsersTable />
      <span style={{marginBottom: '5rem', display: 'block'}}></span>
    </div>
  )
}

export default AdminAllUsersPage
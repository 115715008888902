// file   : EditProfile.js
// purpose: a form for user to enter new profile details to update profile

import React, { useState } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import './stylesheets/Form.css'
import { errorMessage, commonLabel, commonLabel2 } from './CommonComponents'
import { API } from '../API'
import { useSelector, useDispatch } from 'react-redux'
import { selectCurrentUser, setCurrentUser } from '../redux/currentUserSlice'
import { DefaultLoader } from './Loaders'

const UploadProfile = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)
  
  const ERROR_UPDATING = 'Error occured when updating your profile details.'
  const NEED_CONFIRM_PASSWORD = 'Confirm password is required if you want to update password' 

  const [isLoading, setIsLoading] = useState(false)
  const [isSuccessResponse, setIsSuccessResponse] = useState(false) 
  const [isFailedResponse, setIsFailedResponse] = useState(false)
  const [failedMessage, setFailedMessage] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = (values) => {
    setIsLoading(true)
    setIsSubmitting(true)
    setIsFailedResponse(false)
    setIsSuccessResponse(false)

    const userData = {
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      business_name: values.businessName,
      contact: values.contact,
      // company: values.company,
    }

    // if password is filled, but confirmPassword is not filled, shows error
    // else update profile details 
    if(values.password !== '' && values.confirmPassword === '') {
      setIsLoading(false)
      setIsSubmitting(false)
      setFailedMessage(NEED_CONFIRM_PASSWORD)
      setIsFailedResponse(true)
    } else {
      // add newpassword to userdata if user fills the password field
      if (values.password === values.confirmPassword && values.password !== '') {
        userData.password = values.password
      } 
      API.updateUser(userData, currentUser.id)
      .then(res => {
        setIsLoading(false)
        setIsSuccessResponse(true)
        setIsSubmitting(false)
        dispatch(setCurrentUser(res.data))
      })
      .catch(err => {
        console.log(err.response)
        setIsLoading(false)
        setIsSubmitting(false)
        setFailedMessage(ERROR_UPDATING)
        setIsFailedResponse(true)
      })
    }
  }

  return (
    <Formik
      initialValues={{
        firstName: currentUser.first_name,
        lastName: currentUser.last_name,        
        businessName: currentUser.business_name ? currentUser.business_name : '',
        contact: currentUser.contact ? currentUser.contact : '',
        // company: currentUser.company ? currentUser.company : '',
        password: '',
        confirmPassword: ''
      }}
      validationSchema={Yup.object({
        firstName: Yup.string()
          .required('First name is required')
          .max(35, 'Must be 35 characters or less')
          .matches(/^[a-zA-Z0-9]*$/, 'Must only contain alphanumeric characters'),
        lastName: Yup.string()
          .required('Last name is required')
          .max(35, 'Must be 35 characters or less')
          .matches(/^[a-zA-Z0-9]*$/, 'Must only contain alphanumeric characters'),
        password: Yup.string()
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/,
            '8 - 16 characters, at least one letter and one number' 
          ),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords much match'),
        businessName: Yup.string()
          .max(35,'Must be 35 characters or less')
          .matches(/^[a-zA-Z0-9\S\s\n]*$/, 'Must only contain alphanumeric characters and space'),
        contact: Yup.string()   
          .max(60,'Must be 60 characters or less')
          .matches(/^[a-zA-Z0-9\S\s\n]*$/, 'Must only contain alphanumeric characters and space'),
        // company: Yup.string()
        //   .max(60,'Must be 60 characters or less')
        //   .matches(/^[a-zA-Z0-9\S\s\n]*$/, 'Must only contain alphanumeric characters and space'),

      })}
      onSubmit={(values, actions) => { 
        handleSubmit(values) 
      }}
    > 
      <div className='form-container'>
        <p className='form-container-title'>Edit Profile</p>
        <Form>
          {commonLabel('firstName', 'First name')}
          <Field 
            name='firstName'
            type='text'
            disabled={isSubmitting}
            placeholder={currentUser.first_name}
          />
          {errorMessage('firstName')}
          {commonLabel('lastName', 'Last name')}         
          <Field 
            name='lastName'
            type='text'
            disabled={isSubmitting}
            placeholder={currentUser.last_name}
          />
          {errorMessage('lastName')}

          {commonLabel2('password', 'New Password')}
          <Field 
            name='password'
            type='password'
            disabled={isSubmitting}
            placeholder='Enter your new password'
          /> 
          {errorMessage('password')}

          {commonLabel2('confirmPassword', 'Confirm password')}
          <Field 
            name='confirmPassword'
            type='password'
            disabled={isSubmitting}
            placeholder='Confirm your new password'
          /> 
          {errorMessage('confirmPassword')}

          {commonLabel2('businessName', 'Business name')}
          <Field 
            name='businessName'
            type='businessName'
            disabled={isSubmitting}
            placeholder={currentUser.business_name === '' ? 'What is your business name?' : currentUser.business_name}
          /> 
          {errorMessage('businessName')}
          {commonLabel2('contact', 'Contact')}
          <Field 
            name='contact'
            type='contact'
            disabled={isSubmitting}
            placeholder={currentUser.contact === '' ? 'What is your contact number?' : currentUser.contact}
          /> 
          {errorMessage('contact')}
          
          {/* {commonLabel2('company', 'Company')}
          <Field 
            name='company'
            type='company'
            disabled={isSubmitting}
            placeholder={currentUser.company === '' ? 'Where do you work?' : currentUser.company}
          /> 
          {errorMessage('company')}         */}
          {isFailedResponse && 
            <p className='form-failed-message'>
              {failedMessage}
            </p>
          }
          {!isLoading &&
            <div className='editprofile-submit-container'>
              <input type="submit" value="Update" />
            </div>
          }
          <DefaultLoader isLoading={isLoading} />
          {isSuccessResponse && 
            <p className='form-success-message'>
              You have successfully updated your profile details.
            </p>
          }
        </Form>
      </div>
    </Formik>
  )
}

export default UploadProfile

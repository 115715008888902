// file   : NotFoundPage.js
// purpose: a default page to deal with routes not connected to pages

import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'

const divStyle = {
  fontFamily: '\'Roboto\', sans-serif'
}

const hStyle = {
  width: '90%',
  margin: '1rem auto 1rem auto',
  textAlign: 'center',
}

const linkStyle = {
  display: 'block',
  textAlign: 'center',
  width: '90%',
  margin: '0 auto'
}

const NotFoundPage = () => {
  return (
    <div style={divStyle}>
      <Header />
        <h1 style={hStyle}>Page is not found</h1>
        <Link to='/' style={linkStyle}>Go back to home page</Link>
      <Footer />
    </div>
  )
}

export default NotFoundPage


// file   : store.js
// purpose: combine reducers, setup persist redux and configure store 

import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import currentUserReducer from '../redux/currentUserSlice'
import sideMenuReducer from '../redux/sideMenuSlice'
import coursesReducer from '../redux/coursesSlice'
import moduleResultsReducer from '../redux/moduleResultsSlice'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer, FLUSH, REGISTER, REHYDRATE, PAUSE, PERSIST, PURGE } from 'redux-persist'

const appReducer = combineReducers({
  currentUser: currentUserReducer,
  sideMenu: sideMenuReducer,
  courses: coursesReducer,
  currentModuleResults: moduleResultsReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'currentUser/authLogout') {
    localStorage.removeItem('persist:root')
    state = undefined
  }
  return appReducer(state, action)
}

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
});

let persistor = persistStore(store)

export { store, persistor }

// file   : EmailResetPassswordPage.js
// purpose: show form to fill in details for resetting password when forgot password

import React from 'react'
import Header from '../../components/Header'
import EmailResetPasswordForm from '../../components/EmailResetPasswordForm'
import Footer from '../../components/Footer'
import { useSelector } from 'react-redux'
import { selectCurrentAuth, selectCurrentisAdmin } from '../../redux/currentUserSlice'
import { useCheckToken } from '../../components/CustomHooks'
import { Redirect } from 'react-router-dom'

const EmailResetPasswordPage = () => {
  const isLoggedIn = useSelector(selectCurrentAuth)
  const isAdmin = useSelector(selectCurrentisAdmin)

  useCheckToken()
  
  if (isLoggedIn && isAdmin) return <Redirect to='/admin' />
  if (isLoggedIn && !isAdmin) return <Redirect to='/' />

  return (
    <div>
      <Header />
      <EmailResetPasswordForm />
      <Footer />
    </div>
  )
}

export default EmailResetPasswordPage
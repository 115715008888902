// file   : ModuleQuiz.js
// purpose: quiz element for module content

import React, { useEffect, useState } from 'react'
import './stylesheets/ModuleQuiz.css'

const ModuleQuiz = (props) => {
  // track the option selected by user 
  const [selectedOption, setSelectedOption] = useState('')
  const { isSubmit, updateAnswers, questionNo, quizContent } = props
  const { body, option_A, option_B, option_C, option_D, correct_ans } = quizContent
  const optionName = 'option-' + questionNo
  
  useEffect(() => {
      setSelectedOption('')
  }, [quizContent])

  // update selected option in local state and in parent component: ModuleContent
  const onChange = (e) => {
    setSelectedOption(e.currentTarget.value)
    updateAnswers(questionNo - 1, e.currentTarget.value === correct_ans ? 1 : -1)
  }

  // add className 'modulequiz-option-wrong' to div of selected option if that option is the wrong answer
  const optionClass = 'modulequiz-option'
  const submittedOptionClass = (value) => 'modulequiz-option ' + (value === selectedOption && value !== correct_ans ? 'modulequiz-option-wrong' : '') 
  const divClassName = (option) => isSubmit ? submittedOptionClass(option) : optionClass

  // labels for option a to d
  const optionLabel = (optionBody) => 
    (
      <>
        <label htmlFor="option" className='modulequiz-label'>{optionBody}</label>
        <br />
      </>
    )
  
  return (
    <div className='modulequiz-container'>
      <p className='modulequiz-question'>{`Q${questionNo}. ${body}`}</p>
      <div className={divClassName('a')}>
        <span>a. </span>
        <input 
          disabled={isSubmit} type="radio" name={optionName} value="a" 
          checked={selectedOption === 'a'} onChange={onChange} className='modulequiz-input' 
        />
        {optionLabel(option_A)}
      </div>
      <div className={divClassName('b')}>
        <span>b. </span>
        <input 
          disabled={isSubmit} type="radio" name={optionName} value="b" 
          checked={selectedOption === 'b'} onChange={onChange} className='modulequiz-input' 
        />
        {optionLabel(option_B)}
      </div>
      <div className={divClassName('c')}>
        <span>c. </span>
        <input 
          disabled={isSubmit} type="radio" name={optionName} value="c" 
          checked={selectedOption === 'c'} onChange={onChange} className='modulequiz-input' 
        />
        {optionLabel(option_C)}
      </div>
      <div className={divClassName('d')}>
        <span>d. </span>
        <input 
          disabled={isSubmit} type="radio" name={optionName} value="d" 
          checked={selectedOption === 'd'} onChange={onChange} className='modulequiz-input' 
        />
        {optionLabel(option_D)}
      </div>  
      <span style={{marginBottom: '1rem', display: 'block'}}></span>
    </div>
  )
}

export default ModuleQuiz
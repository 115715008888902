// file   : ApiKeyComponent.js
// purpose: renders a field holding API key where refreshing, 
//          creating and deleting API key is possible

import React, { useEffect, useState } from 'react'
import { API } from '../../API'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../redux/currentUserSlice'
import { DefaultToaster, errorToast, successToast } from '../Toasts'
import copy from 'copy-to-clipboard';
import './stylesheets/ApiKeyComponent.css'

const divStyle = {
  width: '90%',
  minWidth: '300px',
  maxWidth: '482.4px',
  border: '1px solid white',
  borderRadius: '8px',
  backgroundColor: 'white',
  padding: '1rem 0.5rem',
  margin: '0 auto',
  fontSize: '0.8rem',
  fontFamily: '\'Roboto\', sans-serif'
}

const labelStyle = { 
  fontSize: '1rem',
  marginLeft: '0.2rem'
}

const ApiKeyComponent = () => {
  const user = useSelector(selectCurrentUser)
  const [apiKey, setApiKey] = useState('')
  const [type, setType] = useState('password')

  const hasApiKey = apiKey !== null && apiKey !== ''
  
  // fetch api key from server if exists
  useEffect(() => {
    if (user) {
      API.requestApiKey(user.id, 'get')
      .then(res => {
        setApiKey(res.data.api_key)
      })
      .catch(err => {
        console.log('err:', err)
      })
    }
  }, [user])

  // toggle show or hide the api key
  const handleToggle = () => {
    if (type === 'password') {
      setType('text')
    } else {
      setType('password')
    }
  }

  // remove the api key if needed
  const handleDelete = () => {
    if (hasApiKey) {
      API.requestApiKey(user.id, 'delete')
      .then(res => {
        console.log(res.data)
        setApiKey('')
        successToast('Removed api key')
      })
      .catch(err => {
        console.log(err)
      })
    } else {
      errorToast('No api key is set')
    }
  }

  // create the api key again after removing
  const handlePost = () => {
    if (!hasApiKey) {
      API.requestApiKey(user.id, 'post')
      .then(res => {
        setApiKey(res.data.api_key)
        successToast('New api key is created')
      })
      .catch(err => {
        console.log(err)
      })
    } else {
      errorToast('Api key exists')
    }
  }

  // refresh the api key if needed
  const handlePut = () => {
    if (hasApiKey) {
      API.requestApiKey(user.id, 'put') 
      .then(res => {
        setApiKey(res.data.api_key)
        successToast('Api key is refreshed')
      })
    } else {
      errorToast('No api key is set')
    }
  }

  // copy the api key immediately by clicking the field
  const handleCopy = () => {
    console.log('clicked')
    if (hasApiKey) {
      if (copy(apiKey)) {
        successToast('Api key copied!')
      } else {
        errorToast('Error occured')
      }
    } else {
      errorToast('No api key is set')
    }
  }

  return (
    <div style={divStyle}>
      <DefaultToaster />
      <label style={labelStyle}>Api Key:</label>
      <div className='api-input-container'>
        {type === 'password' ? <i className="fas fa-eye-slash" onClick={handleToggle}></i> : <i className="fas fa-eye" onClick={handleToggle}></i>} 
        <i className="fas fa-clipboard"></i>
        <input 
          className='api-input'
          onClick={handleCopy}
          type={type}
          placeholder={!hasApiKey ? 'No Api key is set' : ''}
          value={apiKey}
          readOnly={true}
        />
      </div>
      <div className='api-action-tray'>
        <i className="fas action-icon fa-plus-square" onClick={handlePost}></i>
        <i className="fas action-icon fa-sync-alt" onClick={handlePut}></i>
        <i className="fas action-icon fa-trash" onClick={handleDelete}></i>
      </div>
    </div>
  )
}

export default ApiKeyComponent
// file   : Modals.js
// purpose: create modals to be used in other components using third-party library react-modal

import React from 'react'
import ReactModal from 'react-modal';
import './stylesheets/Modals.css'

export const DefaultModal = (props) => {
  return (
    <ReactModal
      isOpen={props.isOpen}
      className={'ReactModal__Content'}
      overlayClassName={'ReactModal__Overlay'}
      ariaHideApp={false}
    >
      {props.children}
    </ReactModal>
  )
}

export const ShortModal = (props) => {
  return (
    <ReactModal
      isOpen={props.isOpen}
      className={'ReactModal__Content'}
      overlayClassName={'Short__ReactModal__Overlay'}
      ariaHideApp={false}
    >
      {props.children}
    </ReactModal>
  )
}
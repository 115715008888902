// file   : App.js
// purpose: main entry to web application, and contain routes to all pages of the website

import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

// import all pages of the website
import PrivateRoute from './components/PrivateRoute'
import AdminRoute from './components/admin/AdminRoute'

import SignupPage from './pages/public-pages/SignupPage'
import LoginPage from './pages/public-pages/LoginPage'
import EmailResetPasswordPage from './pages/public-pages/EmailResetPasswordPage'
import VerifyLinkPage from './pages/public-pages/VerifyLinkPage'
import ForbiddenPage from './pages/public-pages/ForbiddenPage'

import ModulePage from './pages/user-pages/ModulePage'
import ProfilePage from './pages/user-pages/ProfilePage'
import EditProfilePage from './pages/user-pages/EditProfilePage'
import HomePage from './pages/user-pages/HomePage'
import ContentPage from './pages/user-pages/ContentPage'
import Achievement from './pages/user-pages/AchievementPage'

import AdminHomePage from './pages/admin-pages/AdminHomePage'
import AdminCreateModulePage from './pages/admin-pages/AdminCreateModulePage'
import AdminAllUsersPage from './pages/admin-pages/AdminAllUsersPage'
import AdminEditModulePage from './pages/admin-pages/AdminEditModulePage'
import AdminProfilePage from './pages/admin-pages/AdminProfilePage'

import NotFoundPage from './pages/public-pages/NotFoundPage'

const App = () => {
  return (
    <Router>
      <Switch>
        {/* Public Routes */}
        <Route exact path='/signup' component={SignupPage} />
        <Route exact path='/login' component={LoginPage} />
        <Route exact path='/emailresetpassword' component={EmailResetPasswordPage} />
        <Route path='/verifyLink' component={VerifyLinkPage} />
        <Route exact path='/forbidden' component={ForbiddenPage} />

        <Route exact path='/course/:courseid/module/:moduleid' component={ModulePage} />
        <Route exact path='/' component={HomePage} />
        <Route exact path='/content' component={ContentPage} />

        {/* Admin Routes */}
        <AdminRoute exact path='/admin'>
          <AdminHomePage />
        </AdminRoute>
        <AdminRoute exact path='/admin/createmodule/:courseid'>
          <AdminCreateModulePage />
        </AdminRoute>
        <AdminRoute exact path='/allusers'>
          <AdminAllUsersPage />
        </AdminRoute>
        <AdminRoute exact path='/admin/profile'>
          <AdminProfilePage />
        </AdminRoute>
        <AdminRoute exact path='/admin/editprofile'>
          <EditProfilePage />
        </AdminRoute>
        <AdminRoute exact path='/admin/editmodule/:courseid/:moduleid'>
          <AdminEditModulePage />
        </AdminRoute>
        <AdminRoute exact path='/admin/achievement'>
          <Achievement />
        </AdminRoute>

        {/* Service Providers Routes */}
        <PrivateRoute exact path='/profile'>
          <ProfilePage /> 
        </PrivateRoute>
        <PrivateRoute exact path='/editprofile'>
          <EditProfilePage /> 
        </PrivateRoute>
        <PrivateRoute exact path='/achievement'>
          <Achievement /> 
        </PrivateRoute>

        {/* Capture any other routes */}
        <Route component={NotFoundPage} />
      </Switch>
    </Router>
  )
}

export default App
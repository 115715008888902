// file   : ModuleVideo.js
// purpose: video element for module content

import React from 'react'
import ReactPlayer from 'react-player'
import './stylesheets/ModuleVideo.css'

const ModuleVideo = (props) => {
  // using ReactPlayer library to render a player with controls
  return (
    <div> 
      <div className='modulevideo-wrap'>
        <div className='modulevideo-container'>
          <ReactPlayer 
            url={props.videoUrl}
            width="90%"
            height="auto"
            controls={true}
            className='modulevideo-player'
          />
        </div>
      </div>
    </div>
  )
}

export default ModuleVideo
// file   : AllUsersTable.js
// purpose: renders table to show the information of all users

import React, { useEffect, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import UserDetailModal from './UserDetailModal'
import { API } from '../../API'
import { useTable, useSortBy } from 'react-table'
import './stylesheets/AllUsersTable.css'

const useStyles = makeStyles({
  root: {
    margin: '1rem auto',
    width: '90%',
  },
  container: {
    maxHeight: 600
  },
})

const pStyle  = {
  margin: '1rem auto',
  width: '90%',
  fontWeight: 'bolder',
  fontSize: '1.5rem',
  textAlign: 'center',
  fontFamily: '\'Roboto\', sans-serif',
  color: 'darkblue'
}

// uses material-ui components and react-table hooks to implement the users table
const AllUsersTable = () => {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [data, setData] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(8)
  const [showModal, setShowModal] = useState(false)
  const [email, setEmail] = useState('')

  useEffect(() => {
    API.getAllResults()
    .then(res => {
      console.log(res.data)
      setData(res.data)
    })
    .catch(err => {
      if (err) console.log(err)
    })
  }, [])

  const columns = useMemo(() => [
    {
      Header: 'Email',
      accessor: 'email'
    },
    {
      Header: 'Name',
      accessor: row => row.first_name + ' ' + row.last_name,
      id: 'name'
    },
    {
      Header: 'Business Name',
      accessor: 'business_name'
    },
    {
      Header: 'Contact',
      accessor: 'contact'
    },
    {
      Header: 'Company',
      accessor: 'company'
    },
  ], [])

  const sortByOptions = useMemo(() => [
    {
      id: 'email',
      desc: false
    }
  ], [])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    initialState: {
      sortBy: sortByOptions
    }
  }, useSortBy)

  const handleChangeRowsPerPage = e => {
    setRowsPerPage(+e.target.value)
    setPage(0)
  }

  const handleClick = (value) => {
    console.log('value::', value)
    setEmail(value)
    setShowModal(true)
  }

  return (
    <div>
      <p style={pStyle}>Users</p>
      <UserDetailModal showModal={showModal} setShowModal={(bool) => setShowModal(bool)} allData={data} email={email} />
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label='sticky table' {...getTableProps()}>
            <TableHead>
              {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell {...column.getHeaderProps(column.getSortByToggleProps())} align='center'>
                    {column.render('Header')}
                    <span>
                      {' '}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <i className="fas fa-sort-down"></i>
                          : <i className="fas fa-sort-up"></i>
                        : ''}
                    </span>
                  </TableCell>
                ))}
              </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    if (cell.column.id === 'email') {
                      return (
                        <TableCell {...cell.getCellProps()} align='center' onClick={() => handleClick(cell.value)} className='email-cell'>
                          {cell.render('Cell')}
                        </TableCell>
                      )
                    } else {
                      return (
                        <TableCell {...cell.getCellProps()} align='center'>
                          {cell.render('Cell')}
                        </TableCell>
                      )
                    }
                  })}
                </TableRow>
              )
            })}
            </TableBody>
          </Table>
        </TableContainer> 
        <TablePagination 
          rowsPerPageOptions={[8, 15]}
          component='div'
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={(e, newPage) => setPage(newPage)}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}

export default AllUsersTable
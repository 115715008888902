// file   : CreateEditModuleVideo.js
// purpose: create or update video element in module

import React, { useEffect, useState } from 'react'
import VideoDropzone from '../VideoDropzone'
import ReactPlayer from 'react-player'
import '../stylesheets/ModuleVideo.css'

const noticeStyle = {
  textAlign: 'center',
  fontSize: '0.7rem'
}

const inputStyle = {
  maxWidth: '400px',
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',
  width: '90%',
  margin: '0 auto'
}

const CreateEditModuleVideo = (props) => {
  const { data, setAllContent, isSubmitting } = props
  const { allContent, index } = data
  const [videoSrc, setVideoSrc] = useState(null)

  useEffect(() => {
    if (allContent[index].url === '') {
      setVideoSrc(allContent[index].file ? URL.createObjectURL(allContent[index].file) : null)
    } else {
      setVideoSrc(allContent[index].url)
    }
  }, [allContent, index])

  const handleFileDrop = (video) => {
    allContent[index].url = ''
    allContent[index].file = video
    setAllContent(allContent)
    setVideoSrc(URL.createObjectURL(video))
  }

  const handleUrlInput = (value) => {
    allContent[index].file = null
    allContent[index].url = value
    setAllContent(allContent)
    setVideoSrc(value)
  }

  return (
    <div>
      <input 
        style={inputStyle}
        type='url' 
        disabled={isSubmitting} 
        placeholder="What's the video link?" 
        value={allContent[index].url}
        onChange={(e) => handleUrlInput(e.target.value)}
      />
      <p style={{textAlign: 'center', fontSize: '1rem', margin: '0.5rem auto 0.5rem auto'}}>------ or ------</p>
      <VideoDropzone setVideo={(video) => handleFileDrop(video)} isSubmitting={isSubmitting} />
      <p style={noticeStyle}>Video file or link is working if a video preview is shown</p>
      {videoSrc && (
        <div>
          <p style={{textAlign: 'center', margin: '0.5rem auto'}}>Video Preview:</p>
          <div className='modulevideo-wrap'>
            <div className='modulevideo-container'>
              <ReactPlayer 
                url={videoSrc}
                width="90%"
                height="auto"
                controls={true}
                className='modulevideo-player'
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CreateEditModuleVideo
// file   : Loaders.js
// purpose: create loading spinners to be used in other component using third-party library react-spinners

import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import { css } from '@emotion/react'

const override = css`
  display: block;
  margin: 1.7rem auto 0.5rem auto;
`

export const DefaultLoader = (props) => {
  return (
    <ClipLoader size={30} css={override} color={'navy'} loading={props.isLoading} />
  ) 
}
// file   : CommonComponents.js
// purpose: contains common components used in other components

import React from 'react'
import { ErrorMessage } from 'formik'
import redtriangle from '../assets/redtriangle.png'

export const errorMessage = (fieldName) => {
  return (
    <ErrorMessage 
      name={fieldName}
      render={msg => 
        <div className='error-message'>
          <span><img className='error-img' src={redtriangle} alt='error-triangle' /></span>{msg}
        </div>
      } 
    />
  )
}

export const commonLabel = (htmlForName, labelName) => {
  return (
    <label htmlFor={htmlForName}>
      {labelName}<span className='required-red'>*</span>
    </label>
  )
}

export const commonLabel2 = (htmlForName, labelName) => {
  return (
    <label htmlFor={htmlForName}>
      {labelName}<span className='required-red'></span>
    </label>
  )
}

// file   : ForbiddenPage.js
// purpose: a default page to handle unauthorized access to protected routes

import React from 'react'
import Footer from '../../components/Footer'
import Header from '../../components/Header'

const divStyle={
  backgroundColor: 'white',
  fontFamily: '\'Roboto\', sans-serif',
  maxWidth: '400px',
  margin: '1rem auto',
  padding: '1rem',
  borderRadius: '5px'
}

const hStyle={
  textAlign: 'center',
  fontSize: '4rem',
  color: 'darkred'
}

const pStyle={
  textAlign: 'center',
  fontSize: '1rem'
}

const ForbiddenPage = () => {
  return (
    <div>
      <Header />
        <div style={divStyle}>
          <h2 style={hStyle}>403 Error </h2>
          <p style={pStyle}>Sorry, you are not authorized to access this page</p>
        </div>
      <Footer />  
    </div>
  )
}

export default ForbiddenPage
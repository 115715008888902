// file   : index.js
// purpose: root js file that contains entry to the web app, App component,
//          and is where redux store and persistent redux were set up

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { persistor, store } from './app/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'

// global style to all pages
import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
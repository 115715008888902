// file   : AdminCreateCourseModal.js
// purpose: modal that allows admin to create a course
//          by filling in course title and attach course image

import React, { useState, useEffect } from "react";
import NativeSelect from "@material-ui/core/NativeSelect";
import "./stylesheets/AdminCreateCourseModal.css";
import ImageDropzone from "../ImageDropzone";
import { useSelector, useDispatch } from "react-redux";
import { selectAllCourses, setIsRefreshed } from "../../redux/coursesSlice";
import { uploadFileToS3 } from "../../S3Helper";
import { API } from "../../API";
import { DefaultLoader } from "../Loaders";
import { successToast, errorToast } from "../Toasts";
import { DefaultModal } from "../Modals";
import { BootstrapInput } from "../Inputs";
import { STATUS_ALL } from "../Helper";

const AdminCreateCourseModal = (props) => {
  const dispatch = useDispatch();
  const allCourses = useSelector(selectAllCourses);

  const [image, setImage] = useState(null);
  const [courseTitle, setCourseTitle] = useState("");
  const [courseStatus, setCourseStatus] = useState(STATUS_ALL.PUBLISHED);
  const [hasImage, setHasImage] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [isRequiredError, setIsRequiredError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (props.isOpen) {
      setCourseStatus(STATUS_ALL.PUBLISHED)
    }
  }, [props.isOpen])

  // if image is available, create a url for the image and preview the image
  useEffect(() => {
    if (image) {
      setHasImage(true);
      setImageSrc(URL.createObjectURL(image));
    } else {
      setHasImage(false);
      setImageSrc("");
    }
  }, [setHasImage, image]);

  const handleSubmit = () => {
    setIsLoading(true);
    setIsSubmitting(true);
    setIsRequiredError(false);

    // returns error if there is no course title or image attached
    // else stores the course information in server
    if (courseTitle === "" || !image) {
      setIsRequiredError(true);
      setIsLoading(false);
      setIsSubmitting(false);
    } else {
      uploadFileToS3(image, "course-image", "images")
        .then((data) => {
          API.createCourse({
            course_name: courseTitle,
            course_no: allCourses.length + 1,
            course_pic: data.fileUrl,
            status: courseStatus,
          }).then((res) => {
            setIsLoading(false);
            setIsSubmitting(false);
            successToast("Created a course successfully");
            setHasImage(false);
            setImageSrc("");
            setImage(null);
            dispatch(setIsRefreshed(false));
            props.setShowModal(false);
          });
        })
        .catch((err) => {
          errorToast("Error occured when creating a course");
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <DefaultModal isOpen={props.showModal}>
      <div className="modal-clsbutton-container">
        <button
          className="modal-clsbutton"
          onClick={() => props.setShowModal(false)}
        >
          X
        </button>
      </div>
      <p className="admin-create-course-modal-title">Create Course</p>
      <div className="admin-create-course-modal-content">
        <label className="create-course-label">Course title:</label>
        <input
          className="course-title-input"
          type="text"
          disabled={isSubmitting}
          onChange={(e) => setCourseTitle(e.target.value)}
        />
        <label className="create-course-label">Course Image:</label>
        <ImageDropzone
          setImage={(file) => setImage(file)}
          isSubmitting={isSubmitting}
        />
        {hasImage && (
          <img className="preview-image" src={imageSrc} alt="preview" />
        )}
        <label className="create-course-label">Course Status:</label>
        <NativeSelect
          value={courseStatus}
          onChange={e => setCourseStatus(e.target.value)}
          id="course-status-select"
          input={<BootstrapInput />}
        >
          {Object.values(STATUS_ALL).map((v) => (
            <option value={v} key={v}>{v}</option>
          ))}
        </NativeSelect>
        {isRequiredError && (
          <p className="form-failed-message">
            Please fill in all the information required to create a course.
          </p>
        )}

        <div className="admin-create-course-modal-submit-container">
          {!isLoading && (
            <button
              className="admin-create-course-modal-submit"
              type="submit"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Create
            </button>
          )}
          <DefaultLoader isLoading={isLoading} />
        </div>
      </div>
    </DefaultModal>
  );
};

export default AdminCreateCourseModal;

// file   : DropzoneStyle.js
// purpose: contains default styles used by dropzones

export const divStyle = {
  margin: '0 auto'
}

export const baseStyle = {
  padding: '1rem 0.5rem 1rem 0.5rem',
  position: 'relative',
  margin: '0 auto 1rem auto',
  width: '90%',
  minWidth: '260px',
  maxWidth: '320px',
  fontSize: '0.8rem',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: 'lightgrey',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .1s ease-in-out',
  cursor: 'pointer'
}

export const activeStyle = {
  borderColor: '#2196f3'
};

export const acceptStyle = {
  borderColor: '#00e676'
};

export const rejectStyle = {
  borderColor: '#ff1744'
};

export const pStyle = {
  textAlign: 'center'
}
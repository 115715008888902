// file   : HomeContentCourse.js
// purpose: a course component in the home page showing couse image, title and modules

import React from 'react'
import './stylesheets/HomeContentCourse.css'
import { Link } from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress'
import { getTick } from './admin/AdminHelper'
import { STATUS_ALL } from './Helper'
import { selectCurrentUser } from '../redux/currentUserSlice'
import { useSelector } from 'react-redux'

const HomeContentCourse = (props) => {
  const { id, course_name, course_pic, modules } = props.course
  // returns true if there are no questions (best_score === -1) or best_score is more than threshold set for the module
  const isPassed = (moduleResult, threshold) => (moduleResult.best_score >= (threshold === null ? 80 : threshold)) 
                                                || (moduleResult.best_score === -1) 
                                                ? true : false
  const user = useSelector(selectCurrentUser)
  // progress = num of modules completed/total modules in the course  
  const progress = () => {
    const numModules = modules.length
    let numModulesCompleted = 0
    for (let i = 0; i < numModules; i++) {
      if (props.moduleResults.find(e => modules[i].id === e.module_id)) {
        numModulesCompleted++
      }
    }
    return numModulesCompleted/numModules*100
  }

  return (
    props.course && props.moduleResults &&
    <div className='course-container'>
      {/* course image and progress bar */}
      <div className='course-image-container'>
        <img src={course_pic} alt='course' className='course-image' />
        <LinearProgress variant="determinate" value={progress()} />
      </div>
      <div className='course-title-container'>
        <p className='course-title'>{course_name}</p>
      </div>
      {/* list of modules where each row contains module name, score, date and badge */}
      <table className='course-module'>
        <tbody>
          {modules && modules.filter(m => m.status === STATUS_ALL.PUBLISHED).map((m) => {
            const moduleResult = props.moduleResults.find(e => m.id === e.module_id) 
            let icon, date
            if (moduleResult) {
              icon = isPassed(moduleResult, m.threshold) && getTick()
              // score = moduleResult.best_score === -1 ? `complete` : `` + moduleResult.best_score + `%`
              date = isPassed(moduleResult, m.threshold) && moduleResult.best_score_date.split(' ')[0].split("-").reverse().join("/")
            } else {
              // score = `none`
              date = ' '
              icon = ' '
            }
            return (
              <tr key={m.id}>
                <td colSpan={moduleResult ? 1 : 3} className='table-content-link'>
                  <Link to={user ? `/course/${id}/module/${m.id}`:`/login`} className='course-module-link'>
                    {m.title}
                  </Link>
                </td>
                {/* {moduleResult && 
                  <td className='table-content-score'>
                    {score}
                  </td>
                } */}
                {moduleResult && 
                  <td className='table-content-date'>
                    {date}
                  </td>
                }
                {moduleResult &&
                  <td className='table-content-icon'>
                    {icon}
                  </td>
                }
              </tr>
            ) 
          })} 
        </tbody> 
      </table>
    </div>
  )
}

export default HomeContentCourse
// file   : SideMenu.js
// purpose: displays the sidemenu which holds the collapsibles

import React from 'react'
import './stylesheets/SideMenu.css'
import { useSelector, useDispatch } from 'react-redux'
import { selectIsActiveSideMenu, notActiveSideMenu } from '../redux/sideMenuSlice'
import { selectAllCourses } from '../redux/coursesSlice'
import { useWindowSize } from './CustomHooks'
import Collapsible from './Collapsible'

const SideMenu = () => {
  const dispatch = useDispatch()
  const isActiveSideMenu = useSelector(selectIsActiveSideMenu)
  const allCourses = useSelector(selectAllCourses)
  let collapsibleNo = 0

  const [width] = useWindowSize()
  // set the width of sidemenu based on width of browser
  const isActive = isActiveSideMenu ? (width >= 1000 ? '300px' : '170px') : '0px'

  // renders sidemenu which displays a list of courses as collapsibles
  return (
    <div className='sidemenu-container' style={{width: `${isActive}`}}>
      <div className='sidemenu-clsbutton-container'>
        <button className='sidemenu-clsbutton' onClick={() => dispatch(notActiveSideMenu())}>X</button>
      </div>
      <p className='sidemenu-title'>Navigation</p>
      <ol className='sidemenu-collapsibles'>
        {allCourses && allCourses.map((c) => {
          collapsibleNo++
          return (
            <div key={c.id}>
              <Collapsible course={c} collapsibleNo={collapsibleNo} />
              <div className='sidemenu-collapsibles-divider'></div>
            </div>
          )
        })}
      </ol>
    </div>
  )
}

export default SideMenu
// file   : ModuleImage.js
// purpose: image element for module content

import React from 'react'
import './stylesheets/ModuleImage.css'

const ModuleImage = (props) => {
  return (
    <div className='moduleimage-container'>
      <img 
        src={props.imageUrl}
        alt='module-content' 
        className='moduleimage-image'
      />
    </div>
  )
}

export default ModuleImage
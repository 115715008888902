// file   : AdminCreateModuleContent.js
// purpose: main parent component for creating a module for a course

import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectCourse, addModule } from '../../redux/coursesSlice'
import { selectIsActiveSideMenu } from '../../redux/sideMenuSlice'
import { processContent } from '../../S3Helper'
import './stylesheets/AdminCreateModuleContent.css'
import { API } from '../../API'
import { DefaultLoader } from '../Loaders'
import { successToast, errorToast } from '../Toasts'
import CreateEditModuleQuestion from './CreateEditModuleQuestion'
import CreateEditModuleImage from './CreateEditModuleImage'
import CreateEditModuleVideo from './CreateEditModuleVideo'
import CreateEditModuleArticle from './CreateEditModuleArticle'
import { convertFromRaw } from 'draft-js'
import BadgeTray from './BadgeTray'

const AdminCreateModuleContent = () => {
  const { courseid } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const course = useSelector(state => selectCourse(state, courseid))
  const modules = course.modules
  const isActiveSideMenu = useSelector(selectIsActiveSideMenu)
  
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [moduleTitle, setModuleTitle ] = useState('')
  const [previewModuleTitle, setPreviewModuleTitle] = useState(false)

  // eslint-disable-next-line
  const [moduleNo, setModuleNo] = useState(modules ? modules.length + 1 : 1)
  const [threshold, setThreshold] = useState(80)
  const [badgeStyle, setBadgeStyle] = useState(1)
  const [allContent, setAllContent] = useState([])
  const [priority, setPriority] = useState(0)
  let isActive = isActiveSideMenu ? 'admin-sidemenu-active' : null

  // default content state for article when initialised
  const initialContentState = {
    "blocks": [
        {
            "key": "637gr",
            "text": "",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "data": {}
        }
    ],
    "entityMap": {}
  }

  // add module component depends on the content type 
  const createModuleComponent = (content, index) => {
    let component = null
    // highlight the icon based on the module component
    const active = (type) => type === content.type ? 'icon-active' : null

    switch(content.type) {
      case 'image':
        component = (
          <CreateEditModuleImage
            isSubmitting={isSubmitting}
            data={{allContent: allContent, index: index}} 
            setAllContent={setAllContent} 
          />
        )
        break
      case 'video':
        component = (
          <CreateEditModuleVideo 
            isSubmitting={isSubmitting}
            data={{allContent: allContent, index: index}}
            setAllContent={setAllContent}
          />
        )
        break
      case 'question':
          component = (
            <CreateEditModuleQuestion 
              isSubmitting={isSubmitting}
              data={{allContent: allContent, index: index}}
              setAllContent={setAllContent}
            />
          )
          break
      case 'article':
        component = (
          <CreateEditModuleArticle 
            isSubmitting={isSubmitting}
            data={{allContent: allContent, index: index}}
            setAllContent={setAllContent}
          />
        )
        break
      default:
        component = null
    }

    // for each component, there is an icon tray above the component allowing admin to 
    // switch the component type easily or delete the module component itself
    return (
      <div key={index} className='component-container'>
        {<div>
          <div className={`component-icon-tray ${isSubmitting && 'icon-disabled'}`}>
            <i 
              className={`fas icon fa-image ${active('image')}`}
              onClick={() => handleChangeComponent(index, 'image')}
            >
            </i>
            <i 
              className={`fas icon fa-video ${active('video')}`}
              onClick={() => handleChangeComponent(index, 'video')}
            >
            </i>
            <i 
              className={`fas icon fa-question ${active('question')}`}
              onClick={() => handleChangeComponent(index, 'question')}
            >
            </i>
            <i 
              className={`fas icon fa-newspaper ${active('article')}`}
              onClick={() => handleChangeComponent(index, 'article')}
            >
            </i>
            <i className='fas fa-trash' onClick={() => handleDelete(index)}></i>
          </div>
        </div>}
        {component}
      </div>
    )
  }

  // handle the change in component when is changing the component type 
  const handleChangeComponent = (index, newType) => {
    const prio = allContent[index].priority
    if (newType === 'image') {
      allContent.splice(index, 1, {
        type: 'image',
        file: null,
        priority: prio
      })
    } else if (newType === 'video') {
      allContent.splice(index, 1, {
        type: 'video',
        file: null,
        url: '',
        priority: prio
      })
    } else if (newType === 'question') {
      allContent.splice(index, 1, {
        type: 'question',
        body: '', 
        option_A: '', option_B: '', option_C: '', option_D: '', 
        correct_ans: '', 
        priority: prio
      }) 
    } else if (newType === 'article') {
      allContent.splice(index, 1, {
        type: 'article',
        article: initialContentState,
        priority: prio
      })
    }
    setAllContent([...allContent])
  }

  // delete a module component
  const handleDelete = (index) => {
    setAllContent(allContent.filter((_, i) => i !== index))
    successToast('Removed successfully')
  }

  // check if admin is needed to set the score threshold depends on
  // if he has created any quiz/question component
  const shouldSetThreshold = () => {
    for (let i = 0; i < allContent.length; i++) {
      if (allContent[i].type === 'question') {
        return true
      }
    }
    return false
  }

  // validate the input data for each component
  const validateInput = () => {
    // validate the score threshold
    if (isNaN(threshold) || threshold === '') {
      errorToast('Threshold should be a number')
      return false
    } else {
      if (threshold < 0 || threshold > 100) {
        errorToast('Threshold should be 0 - 100')
        return false
      }
    }

    // validate the module title and check if module has content
    if (moduleTitle === '' || allContent.length === 0) {
      return false
    }

    for (let i = 0; i < allContent.length; i++) {
      let content = allContent[i]
      // validate any question/quiz component
      if (content.type === 'question') {
        if (content.body === '' 
          || content.option_A === '' 
          || content.option_B === '' 
          || content.option_C === '' 
          || content.option_D === '' 
          || content.correct_ans === '') {
            return false
        }
      // validate any video component
      } else if (content.type === 'video') {
        if (content.file === null && content.url === '') {
          return false
        }
      // validate any article component 
      } else if (content.type === 'article') {
        const cstate = convertFromRaw(content.article)
        if (!cstate.hasText() || cstate.getPlainText() === '') {
          return false
        }
      // validate any image component
      } else {
        if (content.file === null) {
          return false
        }
      }
    }
    return true
  }

  const handleSubmit = () => {
    setIsLoading(true)
    setIsSubmitting(true)

    if (validateInput()) {
      if (!shouldSetThreshold) {
        setThreshold(-1)
      }

      // process the content, uploading files to s3 and update content with s3 file url 
      processContent(allContent)
      .then(newContent => {
        let data = {
          title: moduleTitle,
          course_id: course.id,
          module_no: moduleNo,
          threshold: threshold,
          badge_style: badgeStyle,
          content: newContent
        }

        // stores the module data in server and redirect to admin home page
        API.createModule(data)
        .then(res => {
          successToast('A new module is created')
          setIsLoading(false)
          dispatch(addModule({courseId: course.id, newModule: res.data}))
          history.push(`/admin`)
        })
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
        setIsSubmitting(false)
      })
    } else {
      setIsLoading(false)
      setIsSubmitting(false)
      errorToast('Required information is missing')
    }   
  }

  // add a create/update image component
  const handleClickImage = () => {
    setPriority(priority + 1)
    setAllContent(allContent.concat({type: 'image', file: null, priority: priority}))
  }

  // add a create/update video component
  const handleClickVideo = () => {
    setPriority(priority + 1)
    setAllContent(allContent.concat({type: 'video', file: null, url: '', priority: priority}))
  }

  // add a create/update quiz component
  const handleClickQuestion = () => {
    setPriority(priority + 1)
    setAllContent(allContent.concat({
      type: 'question', 
      body: '', 
      option_A: '', option_B: '', option_C: '', option_D: '', 
      correct_ans: '', 
      priority: priority
    }))
  }

  // add a create/update article component
  const handleClickArticle = () => {
    setPriority(priority + 1)
    setAllContent(allContent.concat({
      type: 'article',
      article: initialContentState,
      priority: priority
    }))
  }

  return (
    <div className={'create-modulecontent-container ' + (isActive)}>
      <div className='create-modulecontent-main'>
        <div className='create-modulecontent-header'>
          <p className='create-modulecontent-header-title'>
            Create Module
          </p>
          <p className='create-modulecontent-course-title'>
            Course: {course.course_name}
          </p>
          <div className='create-modulecontent-module-title'>
            <p> 
              {previewModuleTitle && moduleTitle}
            </p>
            {!previewModuleTitle && 
              <input 
                type='text'
                disabled={isSubmitting}
                value={moduleTitle}
                placeholder="What's the module title?"
                onChange={e => setModuleTitle(e.target.value)} 
              />
            }
            <i 
              className='fas fa-pen'
              onClick={() => setPreviewModuleTitle(!previewModuleTitle)}
            >
            </i>
          </div>
        </div>
        {allContent && allContent.map((e, idx) => {
          return createModuleComponent(e ,idx)
        })}
        {!isSubmitting && <div className='addcontent-container'>
          <p className='addcontent-title'>Add Content</p>
          <div className='addcontent-icon-tray'>
            <i className="fas icon fa-2x fa-image" onClick={handleClickImage}></i>
            <i className="fas icon fa-2x fa-video" onClick={handleClickVideo}></i>
            <i className="fas icon fa-2x fa-question" onClick={handleClickQuestion}></i>
            <i className="fas icon fa-2x fa-newspaper" onClick={handleClickArticle}></i>
          </div>
        </div>}
        <div style={{width: '90%', maxWidth: '400px', margin: '1rem auto'}}>
          <label style={{margin: '0 auto 0 auto'}}>Score threshold to obtain badge:</label>
          <p style={{color: 'grey', fontSize: '0.8rem', margin: '0.5rem auto'}}>If no question exists in the module, threshold will be set to -1.</p>
          <input
            type='number'
            placeholder='0 - 100. Eg: thresh=80, 80% will get the badge'
            value={threshold}
            step={1}
            min={0}
            max={100}
            onChange={e => setThreshold(e.target.value)}
          />
        </div>
        <BadgeTray 
          isSubmitting={isSubmitting}
          setBadgeStyle={(num) => setBadgeStyle(num)}
          badgeStyle={badgeStyle}
        />
        <div className='create-modulecontent-button-container'>
          {!isLoading && <button className='create-modulecontent-button' disabled={isSubmitting} onClick={handleSubmit}>{'Create'}</button>}
          <DefaultLoader isLoading={isLoading} />
        </div>
      </div>
    </div>
  )
}

export default AdminCreateModuleContent
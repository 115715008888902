// file   : ProfilePage.js
// purpose: page that shows profile details of the user

import React from 'react'
import Header from '../../components/Header'
import Profile from '../../components/Profile'
import Footer from '../../components/Footer'
import { useCheckToken } from '../../components/CustomHooks'

const ProfilePage = () => {
  useCheckToken()

  return (
    <div>
      <Header /> 
      <Profile />
      <span style={{marginBottom: '5rem', display: 'block'}}></span>
      <Footer />
    </div>
  )
}

export default ProfilePage
// file   : SignupForm.js
// purpose: displays a form for registering a user account

import React, { useState } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import './stylesheets/Form.css'
import { errorMessage, commonLabel } from './CommonComponents'
import { Link } from 'react-router-dom'
import { API } from '../API'
import { DefaultLoader } from './Loaders'

const SignupForm = () => {
  // controls the ui, displays loading bar, success/failed message at the right time
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccessResponse, setIsSuccessResponse] = useState(false) 
  const [isFailedResponse, setIsFailedResponse] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  // call api to create a user account with information from signup form
  const handleSubmit = (values) => {
    setIsLoading(true)
    setIsSubmitting(true)
    setIsFailedResponse(false)

    API.signupUser({
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      password: values.password,
      password2: values.confirmPassword,
      avatar: 1,
      privilege: 'user',
    })
    .then(_ => {
      setIsLoading(false)
      setIsSubmitting(true)
      setIsSuccessResponse(true)
    })
    .catch(err => {
      console.log(err)
      if (err.response.status === 500) {
        setIsLoading(false)
        setIsSubmitting(false)
        setIsFailedResponse(true)
      }
    })
  }

  // use third-party library Formik and Yup to create form and form validations
  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        // checkTerms: false
      }}
      validationSchema={Yup.object({
        firstName: Yup.string()
          .required('First name is required')
          .max(35, 'Must be 35 characters or less')
          .matches(/^[a-zA-Z0-9]*$/, 'Must only contain alphanumeric characters'),
        lastName: Yup.string()
          .required('Last name is required')
          .max(35, 'Must be 35 characters or less')
          .matches(/^[a-zA-Z0-9]*$/, 'Must only contain alphanumeric characters'),
        email: Yup.string()
          .required('Email is required')
          .email('Invalid email address'),
        password: Yup.string()
          .required('Password is required')
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/,
            '8 - 16 characters, at least one letter and one number' 
          ),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords much match')
          .required('Confirming password is required'),
        // checkTerms: Yup.boolean()
        //   .required('Required')
        //   .oneOf([true], 'You must accept the terms and conditions.'),
      })}
      onSubmit={(values, actions) => { 
        handleSubmit(values, actions) 
      }}
    > 
      <div className='form-container'>
        <p className='form-container-title'>Sign up</p>
        <Form>
          {commonLabel('firstName', 'First name')}
          <Field 
            name='firstName'
            type='text'
            disabled={isSubmitting}
            placeholder='What is your first name?'
          />
          {errorMessage('firstName')}
          {commonLabel('lastName', 'Last name')}
          <Field 
            name='lastName'
            type='text'
            disabled={isSubmitting}
            placeholder='What is your last name?'
          />
          {errorMessage('lastName')}
          {commonLabel('email', 'Email')}
          <Field 
            name='email'
            type='email'
            disabled={isSubmitting}
            placeholder='Enter your email address'
          /> 
          {errorMessage('email')}
          {commonLabel('password', 'Password')}
          <Field 
            name='password'
            type='password'
            disabled={isSubmitting}
            placeholder='Enter your password'
          /> 
          {errorMessage('password')}
          {commonLabel('confirmPassword', 'Confirm password')}
          <Field 
            name='confirmPassword'
            type='password'
            disabled={isSubmitting}
            placeholder='Enter your password again'
          /> 
          {errorMessage('confirmPassword')}
          {/*<Field */}
          {/*  name='checkTerms'*/}
          {/*  type='checkbox' */}
          {/*  disabled={isSubmitting}*/}
          {/*/>*/}
          {/*<label htmlFor='checkTerms' className='label-checkbox'>*/}
          {/*  I accept the Terms and Conditions*/}
          {/*</label>*/}
          {/*{errorMessage('checkTerms')}*/}
          {isFailedResponse && 
            <p className='form-failed-message'>
              There exists an identical email address. Please use another email address.
            </p>
          }
          {!isLoading && !isSuccessResponse && <input type="submit" value="Sign up" />}
          <DefaultLoader isLoading={isLoading} />
          {isSuccessResponse && 
            <p className='form-success-message'>
              <span style={{fontWeight: 'bolder'}}>Congratulations!</span><br />
              You have created an account 
              for Capital Guardians Academy. 
              Please login to access the training resources.
            </p>
          }
          <Link to='/login' className='link-text'>
            Have an account? Login here!
          </Link>
        </Form>
      </div>
    </Formik>
  )
}

export default SignupForm

// file: AdminHomeContentCourse.js
// purpose: an course component in admin home page allowing admin
//          to delete course/module and update course

import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core";

import "./stylesheets/AdminHomeContentCourse.css";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateModule, setIsRefreshed } from "../../redux/coursesSlice";
import { API } from "../../API";
import { successToast, errorToast } from "../Toasts";
import AdminEditCourseModal from "./AdminEditCourseModal";
import DeleteModal from "./DeleteModal";
import { STATUS_ALL } from "../Helper";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const AdminHomeContentCourse = (props) => {
  const {
    id,
    course_name,
    course_pic,
    modules,
    status = STATUS_ALL.PUBLISHED,
  } = props.course;
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentDeleting, setCurrentDeleting] = useState();
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const history = useHistory();

  // delete course if course has no modules
  const handleDelete = () => {
    if (status !== STATUS_ALL.ARCHIVED) {
      API.editCourse(id, {
        status: STATUS_ALL.ARCHIVED,
      })
        .then((res) => {
          console.log(res.data);
          dispatch(setIsRefreshed(false));
          successToast(`Archived Course`);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (!modules || modules.length === 0) {
      handleCourseDelete(id);
    } else {
      errorToast("Can't delete course with module");
    }
  };

  // trying to delete a module and show the confirm delete modal
  const handleModuleDelete = (moduleId) => {
    setCurrentDeleting({
      targetType: "module",
      targetId: moduleId,
    });
    setShowDeleteModal(true);
  };

  // trying to delete a course and show the confirm delete modal
  const handleCourseDelete = (courseId) => {
    setCurrentDeleting({
      targetType: "course",
      targetId: courseId,
    });
    setShowDeleteModal(true);
  };

  // toggle course status
  const toggleStatus = () => {
    let targetStatus = null;
    switch (status) {
      case STATUS_ALL.PUBLISHED:
      case STATUS_ALL.ARCHIVED:
        targetStatus = STATUS_ALL.DRAFT;
        break;
      case STATUS_ALL.DRAFT:
        targetStatus = STATUS_ALL.PUBLISHED;
        break;
      default:
        return;
    }

    API.editCourse(id, {
      status: targetStatus,
    })
      .then((res) => {
        dispatch(setIsRefreshed(false));
        successToast(`Updated the status as ${targetStatus} successfully`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleModuleStatus = (module) => {
    setIsLoading(true)
    setIsSubmitting(true)
    let targetStatus = null;
    switch (module.status) {
      case STATUS_ALL.PUBLISHED:
      case STATUS_ALL.ARCHIVED:
        targetStatus = STATUS_ALL.DRAFT;
        break;
      case STATUS_ALL.DRAFT:
        targetStatus = STATUS_ALL.PUBLISHED;
        break;
      default:
        return;
    }

    dispatch(updateModule({courseId: id, moduleId: module.id, fieldName:'status', fieldValue:targetStatus}))
    // update the module status in server
    API.updateModuleStatus(module.id, targetStatus)
    .then(res => {
      successToast('The module is updated')
      setIsLoading(false)
      setIsSubmitting(false)
      dispatch(setIsRefreshed(false))
    })
  };

  return (
    props.course && (
      <div className="admin-course-container">
        {/* modal for updating course image and title */}
        <AdminEditCourseModal
          showModal={showModal}
          setShowModal={(bool) => setShowModal(bool)}
          course={props.course}
        />
        <DeleteModal
          showModal={showDeleteModal}
          setShowModal={(bool) => setShowDeleteModal(bool)}
          targetId={currentDeleting?.targetId}
          targetType={currentDeleting?.targetType}
          courseId={id}
        />
        <div className="icon-tray">
          <i
            className="fas fa-trash icon delete-icon"
            onClick={handleDelete}
          ></i>
          <i
            className="fas fa-edit icon edit-icon"
            onClick={() => setShowModal(true)}
          ></i>
          <i
            className="fas fa-plus-square icon plus-icon"
            onClick={() => {
              history.push(`/admin/createmodule/${id}`);
            }}
          ></i>
          <LightTooltip title={status}>
            <i
              className={`fas fa-circle status-icon ${status}-icon`}
              onClick={toggleStatus}
            ></i>
          </LightTooltip>
        </div>
        <div className="admin-course-image-container">
          <img src={course_pic} alt="course" className="admin-course-image" />
        </div>
        <div className="admin-course-title-container">
          <p className="admin-course-title">{course_name}</p>
        </div>
        <table className="admin-course-module">
          <tbody>
            {modules &&
              modules.map((m) => {
                return (
                  <tr key={m.id}>
                    <td className="change-module-status">
                      <LightTooltip title={m.status}>
                        <i
                          className={`fas fa-circle status-icon ${m.status}-icon`}
                          onClick={()=>{toggleModuleStatus(m)}}
                        ></i>
                      </LightTooltip>
                    </td>
                    <td className="admin-table-content-link">
                      <Link
                        to={`/admin/editmodule/${id}/${m.id}`}
                        className="admin-course-module-link"
                      >
                        {m.title}
                      </Link>
                    </td>
                    <td className="admin-table-content-icon">
                      <i
                        className="fas fa-trash"
                        onClick={() => handleModuleDelete(m.id)}
                      ></i>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    )
  );
};

export default AdminHomeContentCourse;
